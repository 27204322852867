import { NavLink } from '../ui';
import { HomeIcon } from '../ui/icons';
import { trackHomeClick } from '../../models/analytics';

const SiteHeaderHomeIcon = ({ hoverColor, style }) => {
  const handleClickHomeLink = () => {
    trackHomeClick();
  };

  return (
    <NavLink
      className="site-header-home__link"
      href="/"
      action="push"
      exact
      onClick={handleClickHomeLink}
      hoverColor={hoverColor}
      style={style}
    >
      <HomeIcon />
    </NavLink>
  );
};

export default SiteHeaderHomeIcon;
