import api from '../config/api';
import { Content } from '../models/content/types';

interface FeedServiceOptions {
  query?: string;
  filter?: string;
  channelId?: string;
  advocateId?: string;
  advocateFilter?: string;
  includeTypes?: string[];
  initiativeId?: string;
  mention_format?: 'raw' | 'human_readable' | 'display_name' | 'with_metadata';
}

type FeedData = {
  type: string;
  attributes: Content;
};

type ServiceMetadata = Record<string, number> & {
  page: Record<string, number>;
};

export type FeedServiceResponse =
  | {
      feed: FeedData[];
      contents?: never;
      meta: ServiceMetadata;
    }
  | { feed?: never; contents: FeedData[]; meta: ServiceMetadata };

class FeedService {
  readonly query?: string;
  readonly filter?: string;
  readonly channelId?: string;
  readonly advocateId?: string;
  readonly advocateFilter?: string;
  readonly includeTypes?: string[];
  readonly initiativeId?: string;
  readonly mention_format?: FeedServiceOptions['mention_format'];

  constructor({
    query,
    filter,
    channelId,
    advocateId,
    advocateFilter,
    includeTypes,
    initiativeId,
    mention_format,
  }: FeedServiceOptions = {}) {
    this.query = query;
    this.filter = filter;
    this.channelId = channelId;
    this.initiativeId = initiativeId;
    this.advocateId = advocateId;
    this.advocateFilter = advocateFilter;
    this.includeTypes = includeTypes;
    this.mention_format = mention_format;
  }

  fetch = ({
    page,
    perPage,
    pinned,
  }: { page?: number; perPage?: number; pinned?: boolean } = {}) => {
    return api.get<FeedServiceResponse>(this.url, {
      params: {
        query: this.query,
        filter_type: this.filter,
        content_channel: this.channelId,
        initiative_tag_id: this.initiativeId,
        include_types: this.includeTypes,
        width: 768,
        page_size: perPage,
        page,
        pinned,
        mention_format: this.mention_format,
      },
    });
  };

  get url() {
    if (this.query) return '/feed/search';

    if (this.advocateId)
      return `/profiles/${this.advocateId}/contents/${this.advocateFilter}`;

    return '/feed';
  }
}

export default FeedService;
