import { useRef, useEffect } from 'react';

// Extends native useEffect method with options to:
// skipMount: Prevents firing on initial mount
const useModifiedEffect = (opts, fn, dep) => {
  const readyState = useRef(!opts.skipMount);
  const readyStateFn = readyState.current
    ? fn
    : () => {
        readyState.current = true;
      };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useEffect(readyStateFn, dep);
};

export default useModifiedEffect;
