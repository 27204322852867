import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Datepicker from 'react-datepicker';
import { TextInput } from './';
import { toDate } from '../../lib/date-formatter';
import { format } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import './ui.scss';

const DateInput = ({
  name,
  label,
  value,
  placeholder,
  dateFormat,
  minDate,
  maxDate,
  onClick,
  onChange,
  onFocus,
  onBlur,
}) => {
  // Since React-Datepicker doesn't support returning and
  // reusing formatted dates, a Date object must be maintained
  // separate from the formatted date used for onboarding form.
  // note: this may be out of date. it seems that the onboarding form needs the format YYYY-MM-DD, which may not have been true when this was written
  const [dateValue, setDateValue] = useState(value ? toDate(value) : null);

  //https://stackoverflow.com/questions/61610738/how-to-put-calendar-icon-to-datepicker-in-right-side
  const CustomInput = React.forwardRef((props, ref) => (
    <TextInput label={label} {...props} ref={ref} icon={'date_range'} />
  ));

  CustomInput.displayName = 'UI.DateInput.CustomInput';

  return (
    <Datepicker
      selected={dateValue}
      id={name}
      name={name}
      autoComplete="off"
      placeholderText={placeholder}
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      dateFormat={dateFormat}
      minDate={minDate}
      maxDate={maxDate}
      popperPlacement="top-end"
      popperModifiers={{ styles: { position: 'fixed' } }}
      customInput={<CustomInput />}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={(val) => {
        const dateVal = val ? toDate(val) : null;
        setDateValue(dateVal);

        // Set formatted date for onboarding form
        onChange({
          target: {
            name,
            value: dateVal ? format(dateVal, dateFormat) : dateVal,
          },
        });
      }}
    />
  );
};

DateInput.defaultProps = {
  value: '',
  dateFormat: 'yyyy-MM-dd',
  minDate: toDate('1920-01-01'),
};

DateInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  dateFormat: PropTypes.string,
  // minDate: PropTypes.instanceOf(Date),
  // maxDate: PropTypes.instanceOf(Date),
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

export default DateInput;
