import {
  useQueryParams,
  StringParam,
  withDefault,
  NumberParam,
  DecodedValueMap,
  encodeDelimitedArray,
  decodeDelimitedArray,
  SetQuery,
} from 'use-query-params';
import { useState } from 'react';
import { isEqual } from 'lodash';

export const CommaArrayParam = {
  encode: (array: string[] | null | undefined) =>
    encodeDelimitedArray(array, ','),

  decode: (arrayStr: string | string[] | null | undefined) =>
    decodeDelimitedArray(arrayStr, ','),
};

export const searchQueryParams = {
  query: StringParam,
  sort: withDefault(StringParam, 'relevance'),
  page: withDefault(NumberParam, 1),
  perPage: withDefault(NumberParam, 20),
};

type UseSearchQueryParamsProps = {
  // If true, the hook will stop watching for query string changes
  disabled?: boolean;
};

type UseSearchQueryParamsResult = [
  SearchParams,
  SetQuery<typeof searchQueryParams>
];

/**
 * Hook to manage search feature query params.
 */
export function useSearchQueryParams({
  disabled,
}: UseSearchQueryParamsProps = {}): UseSearchQueryParamsResult {
  const [liveQueryParams, setLiveQueryParams] =
    useQueryParams(searchQueryParams);
  const [queryParams, setQueryParams] =
    useState<typeof liveQueryParams>(liveQueryParams);

  if (!disabled && !isEqual(liveQueryParams, queryParams)) {
    setQueryParams(liveQueryParams);
  }

  return [queryParams, setLiveQueryParams];
}

export function useDateRangeQueryParams() {
  return useQueryParams({
    ...searchQueryParams,
    to: StringParam,
    from: StringParam,
  });
}

export type SearchParams = DecodedValueMap<typeof searchQueryParams>;

/**
 * Convert a search params object to a query string
 */
export function stringify(params: SearchParams) {
  const query = new URLSearchParams();
  for (const [key, value] of Object.entries(params)) {
    if (value) {
      query.set(key, String(value));
    }
  }
  return query.toString();
}
