import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { uiOperations } from '../models/ui';

const useHeaderMinimizer = (minimize: boolean) => {
  const dispatch = useDispatch();

  useEffect(() => {
    minimize
      ? dispatch(uiOperations.minimizeHeader())
      : dispatch(uiOperations.maximizeHeader());

    return () => {
      dispatch(uiOperations.maximizeHeader());
    };
  }, [dispatch, minimize]);
};

export default useHeaderMinimizer;
