import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { usePatronSelector } from './use-patron-selector';

/**
 * Returns a function that can be used to create a `LinkNavigation` object used by the shared UI components.
 */
export function useLinkNavigation(type: 'push' | 'replace' = 'push') {
  const programPath = usePatronSelector(
    (state) => state.appConfig.program_path
  );
  const history = useHistory();

  return useCallback(
    (pathname: string) => ({
      baseUrl: new URL(programPath, location.origin).href,
      pathname,
      navigate: history[type],
    }),
    [history, programPath, type]
  );
}
