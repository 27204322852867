import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Spinner } from '../../../ui';
import ViewTrigger from '../../../view-trigger';
import useEnterpriseSearch from '../../../../common/use-enterprise-search';
import EndOfFeed from '../../../feed/end-of-feed';
import {
  trackSearchSuccess,
  trackSearchError,
} from '../../../../models/analytics';
import { localizeDate } from '../../../../lib/date-formatter';

import { DEFAULT_SKIP_TO_CONTENT_ID } from '../../../../common/use-skip-to-content';
import { useRouteMatch } from 'react-router';
import { useSearchQueryParams } from '../query-params';
import styles from './search-enterprise.module.scss';
import { Button, Container } from '@socialchorus/shared-ui-components';
import { Icon } from '../../../../components/ui';
import { EnterpriseLoadingSkeletonImage } from './enterprise-loading-skeleton-image/enterprise-loading-skeleton-image';
import { EnterpriseLoadingSkeletonText } from './enterprise-loading-skeleton-text/enterprise-loading-skeleton-text';
import { ErrorBanner } from '../error/error';
import EmptyPlaceholder from '../../topic-page-v2/common/EmptyPlaceholder';
import { IntegrationsBar } from './integrations-bar';
import { SearchResultState } from '../search-screen';
import { CompleteError } from '../overview/overview';
import { FilterBarSkeleton } from '../filter-bar/filter-bar';
import { useIsAssistantActive } from '../../../../common/useIsAssistantActive';

const enterprise_type_include_image: { [key: string]: boolean } = {
  google_drive_search: true,
  box_search: true,
};
const enterprise_type_include_description: { [key: string]: boolean } = {
  zendesk_search: true,
  confluence_search: true,
  servicenow_search: true,
  microsoft365_search: true,
};

interface SearchResultProps {
  item: {
    action: {
      navigation: {
        url: string;
      };
    };
    image?: string;
    title?: string;
    author?: string;
    date?: string;
    snippet?: string;
  };
  enterprise_type: string;
}

const SearchResult: React.FC<SearchResultProps> = ({
  item,
  enterprise_type,
}) => {
  const include_image = enterprise_type_include_image[enterprise_type];

  return (
    <Container
      shadow="light"
      layout="vertical"
      className={styles.EnterpriseSearchItem}
      tag="a"
      href={item.action.navigation.url}
      target="_blank"
      rel="noreferrer"
    >
      {include_image ? (
        item.image ? (
          <div
            className={styles.ItemImage}
            role="img"
            aria-label={item.title}
            style={{ backgroundImage: `url(${item.image})` }}
          />
        ) : (
          <div className={styles.DefaultImage}>
            <Icon className={styles.DefaultImageIcon} type="image" />
          </div>
        )
      ) : null}
      <div className={styles.ItemTitle}>{item.title}</div>
      {item.snippet ? <p>{item.snippet}</p> : null}
      <div className={styles.ItemMeta}>
        {item.author ? (
          <span className={styles.ItemAuthor}>{item.author}</span>
        ) : null}
        {item.date ? (
          <span className={styles.ItemDate}>{localizeDate(item.date)}</span>
        ) : null}
      </div>
    </Container>
  );
};

interface SearchAuthenticationProps {
  data: {
    type: string;
    message: string;
    label: string;
    key: string;
    url: string;
  };
  enterprise_type: string;
  onSuccess: () => void;
  onError?: () => void;
}

const SearchAuthentication: React.FC<SearchAuthenticationProps> = ({
  data,
  enterprise_type,
  onSuccess,
  onError,
}) => {
  const { t } = useTranslation();
  const [cbWindow, setCbWindow] = useState<Window | null>(null);

  useEffect(() => {
    const handleCallback = (e: MessageEvent) => {
      if (!e.data || e.data.callback_key !== data.key) {
        return;
      }
      const { success } = e.data;
      if (success) {
        onSuccess();
      } else if (onError) {
        onError();
      }
      if (cbWindow) {
        cbWindow.close();
        setCbWindow(null);
      }
    };

    window.addEventListener('message', handleCallback, false);

    return () => {
      window.removeEventListener('message', handleCallback, false);
      if (cbWindow) {
        cbWindow.close();
      }
    };
  }, [data.key, onSuccess, onError, cbWindow]);

  const handleClick = () => {
    const newWindow = window.open(data.url);
    setCbWindow(newWindow);
  };

  return (
    <Container fullWidth shadow="light" className={styles.SearchAuth}>
      <div className={styles.SearchAuthTitle}>
        {t('search.enterprise.connection_required_title')}
      </div>
      <p className={styles.SearchAuthDescription}>
        {t('search.enterprise.connection_required_details')}
      </p>
      <Button
        label={data.label}
        variant="primary"
        className={styles.SearchAuthButton}
        onClick={handleClick}
      />
    </Container>
  );
};
interface EnterpriseProps {
  searchReturnedResults: (searchResultState: SearchResultState) => void;
}
const SearchEnterpriseSearchHeader: React.FC<EnterpriseProps> = ({
  searchReturnedResults,
}) => {
  const { params } = useRouteMatch<{
    integrationId: string;
    command: string;
    searchType: string;
  }>();
  const { integrationId, command } = params;

  const isAssistantActive = useIsAssistantActive();
  const [query] = useSearchQueryParams({
    disabled: isAssistantActive,
  });

  const searchText = query.query || '';
  const { t } = useTranslation();

  const {
    result,
    items,
    isFetching,
    isError,
    canLoadMore,
    onReload,
    onLoadMore,
  } = useEnterpriseSearch({ integrationId, command, query: searchText });
  const analyticsData = {
    location: 'search_sharepoint',
    searchType: 'sharepoint',
    searchTerm: searchText,
    integrationId,
    command,
  };
  useEffect(() => {
    if (!isError) {
      trackSearchSuccess(analyticsData);
    } else {
      trackSearchError(analyticsData);
    }
  }, [searchText, isError]);

  if (!result) {
    if (isError) {
      searchReturnedResults(SearchResultState.Error);
      return <CompleteError query={searchText} handleRetry={onReload} />;
    } else {
      searchReturnedResults(SearchResultState.Loading);
      return enterprise_type_include_image[command] ? (
        <>
          <FilterBarSkeleton />
          <EnterpriseLoadingSkeletonImage />
        </>
      ) : (
        <>
          <FilterBarSkeleton />
          <EnterpriseLoadingSkeletonText
            description={enterprise_type_include_description[command]}
          />
        </>
      );
    }
  } else {
    searchReturnedResults(SearchResultState.HasResults);
  }

  let content = null;
  switch (result.type) {
    case 'result': {
      content = (
        <div className={styles.SearchResults}>
          {items.map((item, index) => (
            <SearchResult key={index} item={item} enterprise_type={command} />
          ))}
        </div>
      );
      break;
    }
    case 'auth': {
      if (!isFetching) {
        content = (
          <SearchAuthentication
            data={result}
            enterprise_type={command}
            onSuccess={onReload}
            onError={onReload}
          />
        );
      }
      break;
    }
    case 'error': {
      if (!isFetching && items.length === 0) {
        searchReturnedResults(SearchResultState.Error);
        content = <CompleteError query={searchText} handleRetry={onReload} />;
      }
      break;
    }
  }

  // result.total is the total number of results, not all services provide it - TODO: can/should this bubble up?
  const resultsCount = result?.type === 'result' ? result.total : null;

  return (
    <section
      className={styles.EnterpriseSearch}
      id={DEFAULT_SKIP_TO_CONTENT_ID}
    >
      <div>
        {!isError && <IntegrationsBar />}
        {content}
        {isError ? (
          <ErrorBanner handleRetry={onReload} />
        ) : isFetching ? (
          <Spinner center={!result} />
        ) : canLoadMore ? (
          <ViewTrigger key={items.length} onInview={onLoadMore} />
        ) : items.length ? (
          <EndOfFeed />
        ) : (
          result?.type === 'result' && (
            <EmptyPlaceholder
              className={styles.EmptyFeedContainer}
              title={t('search.no_results.title')}
              description={t('search.no_results.description')}
            />
          )
        )}
      </div>
    </section>
  );
};

export default SearchEnterpriseSearchHeader;
