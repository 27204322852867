import { useTranslation } from 'react-i18next';

import { useContentLikes } from '../../../../common/use-content-likes';
import { ValueType } from '../../../../lib/utility-types';
import { Avatar, Button, Link, Modal, Spinner } from '../../../ui';

import './view-likes-modal.scss';
import { getContentAuthorProfileLink } from '../../../../models/content/helpers';
import useScreenSize from '../../../../common/use-screen-size';
import useFeatureFlag from '../../../../common/use-feature-flag';
import { Feature } from '../../../../models/features/features';
import classNames from 'classnames';
import ViewTrigger from '../../../view-trigger';
import { usePatronSelector } from '../../../../common/use-patron-selector';
import { contentSelectors } from '../../../../models/content';

type ViewLikesModalProps = {
  close: () => void;
  contentId: number;
};

export function ViewLikesModal({ close, contentId }: ViewLikesModalProps) {
  const { t } = useTranslation();
  const tescoQ3 = useFeatureFlag(Feature.TESCO_Q3);

  const likeCount = usePatronSelector((s) =>
    contentSelectors.getContentLikeCount(s, { contentId })
  );

  const { likes, isLoading, canLoadMore, isLoadingMore, fetchNextPage } =
    useContentLikes(contentId);
  const { isMobile } = useScreenSize();

  const mobileFullscreen = isMobile && tescoQ3;

  return (
    <Modal
      className={classNames(
        'view-likes-modal',
        tescoQ3 && 'mobile-changes-enabled'
      )}
      lockScroll
      onClose={close}
    >
      <header className="view-likes-modal__header">
        {!mobileFullscreen && (
          <div className="view-likes-modal__header--headings">
            <h2 className="title">{t('content.like_list')}</h2>
          </div>
        )}
        <div className="view-likes-modal__header--actions">
          <Button theme="icon-only" icon="close" onClick={close} />
        </div>
      </header>

      <div className="view-likes-modal__body">
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            {mobileFullscreen && (
              <div className="text-body-1 view-likes-modal__like--count">
                {t('content.likes_with_count', { count: likeCount })}
              </div>
            )}
            <ul className="view-likes-list">
              {likes.map((like) => (
                <LikeListItem
                  key={like.user_id}
                  like={like}
                  closeModal={close}
                />
              ))}
              {isLoadingMore ? (
                <Spinner />
              ) : canLoadMore ? (
                <ViewTrigger offset="50px" onInview={() => fetchNextPage()} />
              ) : null}
            </ul>
          </>
        )}
      </div>

      {!mobileFullscreen && (
        <footer className="view-likes-modal__footer">
          <Button theme="primary" onClick={close}>
            {t('common.close')}
          </Button>
        </footer>
      )}
    </Modal>
  );
}

export const ID = 'Overlays.VIEW_LIKES_MODAL';

type LikeListItemProps = {
  closeModal: () => void;
  like: ValueType<ReturnType<typeof useContentLikes>['likes']>;
};

function LikeListItem({ closeModal, like }: LikeListItemProps) {
  return (
    <li>
      <Link
        href={getContentAuthorProfileLink(like.user_id)}
        action="push"
        onClick={closeModal}
      >
        <div className="view-likes-list__item">
          <Avatar
            role="presentation"
            className="view-likes-list__item--avatar"
            src={like.avatar.url || undefined}
            bgColor={like.avatar.color}
          />
          <span className="view-likes-list__item--name">{like.name}</span>
          <span className="view-likes-list__item--description">
            {like.details}
          </span>
        </div>
      </Link>
    </li>
  );
}
