import { TopicShortcut } from '../../../../../models/channels/types';
import { Shortcut } from '../../../../ui/shortcut';

type TopicPageShortcutProps = {
  shortcut: TopicShortcut;
};

export function TopicPageShortcut({ shortcut }: TopicPageShortcutProps) {
  return (
    <Shortcut
      label={shortcut.name}
      description={shortcut.description}
      imgSrc={shortcut.icon_url}
      links={shortcut.links.map(({ name, url }) => ({
        url,
        label: name,
      }))}
    />
  );
}
