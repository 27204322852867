import { useState } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import CommentService from '../../services/comment';

import { Button, LikeIcon, LikeIconOutline } from '../ui';
import Heartsplosion from './heartsplosion';

import { trackCommentEvent } from '../../models/comments/analytics';
import { getFeatureFlag, Feature } from '../../models/features/features';

const CommentActionLike = ({ comment }) => {
  const [liked, setLiked] = useState(comment.liked);
  const [canLike, setCanLike] = useState(comment.can_like);
  const [likeCount, setLikeCount] = useState(comment.like_count);
  const [isShowingHeartsplosion, setIsShowingHeartsplosion] = useState();
  const likeIconType = useSelector((state) =>
    getFeatureFlag(state, Feature.LIKE_ICON_TYPE)
  );

  const { t } = useTranslation();

  const empyLikes = likeCount == 0;

  const commentService = new CommentService({
    contentId: comment.content_id,
    commentId: comment.id,
  });

  const className = cx('comment__action', 'comment__action--like', {
    'comment__action--liked': liked,
    'comment__action--can-like': canLike || liked,
    'comment__action--like--empty': empyLikes,
  });

  const likeComment = () => {
    if (!canLike) {
      if (!likeIconType) showHeartsplosion();
      return;
    }

    trackCommentEvent('like', comment, !!comment.reply_to_post_number);

    commentService.like();

    setLiked(true);
    setCanLike(false);
    setLikeCount(likeCount + 1);
  };

  const unlikeComment = () => {
    trackCommentEvent('unlike', comment, !!comment.reply_to_post_number);

    commentService.unlike();

    setLiked(false);
    setCanLike(true);
    setLikeCount(Math.max(0, likeCount - 1));
  };

  const showHeartsplosion = () => {
    setIsShowingHeartsplosion(true);
  };

  const closeHeartsplosion = () => {
    setIsShowingHeartsplosion(false);
  };

  const handleClick = () => {
    liked ? unlikeComment() : likeComment();
  };

  return (
    <div className={className}>
      <Button
        onClick={handleClick}
        aria-label={t('content.like', { count: 1 })}
      >
        <span className="icons">
          <LikeIconOutline />
          <LikeIcon />
        </span>

        <span className="count">{empyLikes ? null : likeCount}</span>
      </Button>

      {isShowingHeartsplosion && (
        <Heartsplosion onComplete={closeHeartsplosion} />
      )}
    </div>
  );
};

export default CommentActionLike;
