import axios, { AxiosResponse } from 'axios';
import { useState, useEffect, useCallback, useReducer, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FlashMessage } from '../components/comments/comment-flash-message';
import { commentFeedReducer, SortOrder } from '../models/comments/feeds';
import {
  Comment,
  CommentPostBatchResponse,
  CommentReply,
  CommentWithReplies,
  isCommentWithReplies,
} from '../models/comments/types';
import CommentService from '../services/comment';

type UseCommentFeedProps = {
  contentId: number;
  sortOrder: SortOrder;
  commentId?: number;
  fetchSingleThreadReplies?: boolean; // Fetch all replies if the commentId is provided
};

const MAX_ERROR_RETRIES = 5;

const useCommentFeed = ({
  contentId,
  sortOrder,
  commentId,
  fetchSingleThreadReplies,
}: UseCommentFeedProps) => {
  const [comments, dispatch] = useReducer(commentFeedReducer, []);

  const [page, setPage] = useState(0);
  const [message, setMessage] = useState<FlashMessage>();
  const [hasNextPage, setHasNextPage] = useState<boolean>();
  const [isFetching, setIsFetching] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
  const [pagesFetched, setPagesFetched] = useState<number[]>([]);
  const [errorRetryCount, setErrorRetryCount] = useState(0);

  const [translateAllActive, setTranslateAllActive] = useState(false);

  const { t } = useTranslation();

  const handleFetchSuccess = useCallback(
    (res: AxiosResponse<CommentPostBatchResponse>, reset?: boolean) => {
      const newComments = res.data.data.map<Comment>((comment) => ({
        ...comment.attributes,
        id: Number(comment.id),
      }));

      const curPage = res.data.meta.current_page;
      const pageCount = res.data.meta.page_count;

      setPagesFetched((prevPagesFetched) => {
        const newPagesFetched = reset
          ? [curPage]
          : [...prevPagesFetched, curPage];

        setHasNextPage(pageCount > 0 && !newPagesFetched.includes(pageCount));

        return newPagesFetched;
      });

      dispatch({
        type: reset ? 'SET_COMMENTS' : 'APPEND_COMMENTS',
        payload: { comments: newComments },
      });

      setPage(curPage);
      setErrorRetryCount(0);
      setIsFetching(false);
    },
    []
  );

  const handleFetchFailure = useCallback(() => {
    setMessage({ text: t('comments.errors.fetch'), type: 'error' });
    setErrorRetryCount((count) => count + 1);
    setIsFetching(false);
  }, [t]);

  const fetchComments = useCallback(
    async (reset?: boolean) => {
      setIsFetching(true);
      if (reset) {
        dispatch({ type: 'SET_COMMENTS', payload: { comments: [] } });
      }

      const service = new CommentService({
        contentId,
      });

      try {
        const res = await service.fetch({
          params: {
            page: reset ? 1 : page + 1,
            sort_order: sortOrder,
            translate: translateAllActive,
          },
        });

        handleFetchSuccess(res, reset);
      } catch (err) {
        if (axios.isAxiosError(err)) {
          handleFetchFailure();
        } else {
          throw err;
        }
      } finally {
        setHasFetched(true);
      }
    },
    [
      contentId,
      handleFetchFailure,
      handleFetchSuccess,
      page,
      sortOrder,
      translateAllActive,
    ]
  );

  const fetchReplies = useCallback(
    async (comment: Comment | CommentWithReplies, reset?: boolean) => {
      setIsFetching(true);

      try {
        const service = new CommentService({
          contentId,
          replyToId: comment.id,
        });

        const res = await service.fetch({
          params: {
            translate: translateAllActive,
            page: isCommentWithReplies(comment)
              ? comment.replies_current_page + 1
              : 1,
            page_size: 40,
          },
        });

        const replies = res.data.data
          .map<CommentReply>((reply) => ({
            ...reply.attributes,
            id: Number(reply.id),
            reply_to_id: comment.id,
          }))
          .reverse();

        dispatch({
          type: 'UPDATE_COMMENT',
          payload: {
            comment: {
              ...comment,
              reply_count: replies.length,
              reply_authors: replies.map((reply) => reply.author),
              replies:
                reset || !isCommentWithReplies(comment)
                  ? replies
                  : replies.concat(comment.replies),
              replies_page_count: res.data.meta.page_count, // Pagination construct, not yet used
              replies_current_page: res.data.meta.current_page, // Pagination construct, not yet used
            } as CommentWithReplies,
          },
        });

        setIsFetching(false);
      } catch (err) {
        if (axios.isAxiosError(err)) {
          handleFetchFailure();
        } else {
          throw err;
        }
      }
    },
    [contentId, translateAllActive, handleFetchFailure]
  );

  const fetchSingleComment = useCallback(
    async (commentId: number) => {
      let service = new CommentService({
        contentId,
        commentId,
      });

      try {
        const res = await service.fetch();
        let comment: Comment | CommentWithReplies = {
          ...res.data.data.attributes,
          id: Number(res.data.data.id),
        };

        dispatch({
          type: 'SET_COMMENTS',
          payload: { comments: [comment] },
        });

        if (comment.reply_to_post_number !== null) {
          service = new CommentService({
            contentId,
            postNumber: comment.reply_to_post_number,
          });

          const parentRes = await service.fetchByPostNumber();
          const parentComment = {
            ...parentRes.data.data.attributes,
            id: Number(parentRes.data.data.id),
          };

          comment = {
            ...parentComment,
            replies: [
              {
                ...comment,
                reply_to_id: parentComment.id,
              },
            ],
          };

          dispatch({
            type: 'SET_COMMENTS',
            payload: { comments: [comment] },
          });
        } else if (fetchSingleThreadReplies) {
          await fetchReplies(comment);
        }

        setPagesFetched([]);
        setIsFetching(false);
      } catch (err) {
        if (axios.isAxiosError(err)) {
          handleFetchFailure();
        } else {
          throw err;
        }
      } finally {
        setHasFetched(true);
      }
    },
    [contentId, fetchReplies, fetchSingleThreadReplies, handleFetchFailure]
  );

  const addComment = (comment: Comment) => {
    if (comment.edited) {
      dispatch({ type: 'UPDATE_COMMENT', payload: { comment } });
    } else if (comment.reply_to_post_number) {
      return addReply(comment);
    } else {
      dispatch({ type: 'ADD_COMMENT', payload: { comment, sortOrder } });
    }
  };

  const updateComment = (comment: Comment) => {
    dispatch({ type: 'UPDATE_COMMENT', payload: { comment } });
  };

  const removeComment = (comment: Comment) => {
    dispatch({ type: 'REMOVE_COMMENT', payload: { comment } });
  };

  const addReply = (comment: Comment) => {
    const commentIndex = comments.findIndex(
      (c) => c.post_number === comment.reply_to_post_number
    );
    const parentComment = comments[commentIndex];

    if (!isCommentWithReplies(parentComment)) {
      return fetchReplies(parentComment);
    }

    const parentReplies = parentComment.replies;
    const replies = [comment, ...parentReplies];

    dispatch({
      type: 'UPDATE_COMMENT',
      payload: {
        comment: {
          ...parentComment,
          reply_count: parentComment.reply_count + 1,
          replies,
        } as CommentWithReplies,
      },
    });
  };

  //helper function to make updating a partial comment easier. kinda just using a stub to put off refactoring all of this.
  //could just set the value in the comment and call updateComment, but this makes it easier to understand the intent of the update code.
  const updateCommentData = (
    comment: Comment,
    updateValues: Partial<Comment>
  ) => {
    dispatch({
      type: 'UPDATE_COMMENT',
      payload: {
        comment: {
          id: comment.id,
          reply_to_post_number: comment.reply_to_post_number, //this is the main gotcha that this function helps us avoid
          ...updateValues,
        },
      },
    });
  };

  const updateCommentsData = (
    comments: Comment[],
    updateValues: Partial<Comment>
  ) => {
    dispatch({
      type: 'UPDATE_COMMENTS',
      payload: {
        comments: comments.map((comment) => ({
          id: comment.id,
          reply_to_post_number: comment.reply_to_post_number, //this is the main gotcha that this function helps us avoid
          ...updateValues,
        })),
      },
    });
  };

  const onTranslateCommentFail = useCallback(() => {
    setMessage({ text: t('comments.errors.fetch'), type: 'error' });
  }, [t]);

  const batchTranslateComments = useCallback(
    async (requestedComments: Comment[], translate: boolean) => {
      if (requestedComments.length === 0) {
        return;
      }

      try {
        const service = new CommentService({
          contentId,
        });

        updateCommentsData(requestedComments, {
          translationInProgress: true,
        });

        //fetch all comments with the new translation state
        const res = await service.fetchByFilter({
          params: {
            translate,
            post_ids: requestedComments.map((comment) => comment.id),
          },
        });

        const newComments = res.data.data.map((comment) => ({
          ...comment.attributes,
          id: Number(comment.id),
        }));

        //update all comments with the new translation state
        dispatch({
          type: 'UPDATE_COMMENTS',
          payload: { comments: newComments },
        });
      } catch (e) {
        onTranslateCommentFail();
        throw e;
      } finally {
        //clean up translation 'in progress' state
        updateCommentsData(requestedComments, {
          translationInProgress: false,
        });
      }
    },
    [contentId, onTranslateCommentFail]
  );

  useEffect(() => {
    if (!commentId || errorRetryCount > MAX_ERROR_RETRIES) return;

    setIsFetching(true);
    setTimeout(() => fetchSingleComment(commentId), errorRetryCount * 1000);
  }, [commentId, errorRetryCount, fetchSingleComment]);

  useEffect(() => {
    if (
      isFetching ||
      commentId ||
      pagesFetched.includes(page) ||
      errorRetryCount > MAX_ERROR_RETRIES
    ) {
      return;
    }

    setIsFetching(true);
    setTimeout(() => fetchComments(true), errorRetryCount * 1000);
  }, [
    commentId,
    errorRetryCount,
    fetchComments,
    isFetching,
    page,
    pagesFetched,
  ]);

  useEffect(() => {
    // Reset page metadata when contentId or sortOrder changes
    setPage(0);
    setPagesFetched([]);
  }, [contentId, sortOrder]);

  return {
    page,
    comments,
    message,
    setMessage,
    hasNextPage,
    fetchNextPage: fetchComments,
    fetchReplies,
    isFetching: isFetching || !hasFetched,
    addComment,
    updateComment,
    updateCommentData,
    removeComment,
    batchTranslateComments,
    translateAllActive,
    setTranslateAllActive,
  };
};

export { SortOrder };
export default useCommentFeed;
