import { Container } from '@socialchorus/shared-ui-components';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch } from 'react-router';
import useScreenTitle from '../../../common/use-screen-title';
import Page from '../../ui/page';
import SecondarySearchNav, {
  NavItem,
  SecondarySearchNavLoadingSkeleton,
} from './secondary-search-nav/secondary-search-nav';
import styles from './search.module.scss';
import React, { useCallback, useState } from 'react';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { ContentSearch } from './content/content';
import { FilterBar, FilterBarSkeleton } from './filter-bar/filter-bar';
import { PeopleSearch } from './people/people';
import AssistantService from '../../../services/assistant';
import { useProgram } from '../../../common/use-program';
import { advocateSelectors } from '../../../models/advocate';
import { usePatronSelector } from '../../../common/use-patron-selector';
import useSWR from 'swr';
import { TopicSearch } from './topics/topics-search';
import { searchQueryParams } from './query-params';
import SearchEnterprise from './enterprise/search-enterprise';
import Overview from './overview/overview';
import { useCategoryMetadata } from './filter-bar/queries';
import { DocumentSearch } from './documents/documents';
import useFeatureFlag from '../../../common/use-feature-flag';
import { Feature } from '../../../models/features/features';
import { ShortcutSearch } from './shortcuts/shortcuts';

export type SearchType = typeof searchTypes[number];

export const searchTypes = [
  'overview',
  'posts',
  'topics',
  'people',
  'documents',
  'enterprise',
  'shortcuts',
] as const;

export function useSearchRoute() {
  const { params } = useRouteMatch<{
    searchType: SearchType;
    integrationId?: string;
  }>();
  return params;
}
export const useIntegrationSearchProvidersFetcher = () => {
  const program = useProgram();
  const advocateId = usePatronSelector((state) =>
    advocateSelectors.getAdvocateId(state)
  );
  const assistant = new AssistantService(program.id, advocateId);
  const { data, ...rest } = useSWR('search_providers', async () => {
    const integrationSearchProviders = await assistant.fetchSearchProviders();
    return integrationSearchProviders;
  });

  return {
    integrationSearchProviders: data || [],
    ...rest,
  };
};

export enum SearchResultState {
  Empty,
  HasResults,
  Loading,
  Error,
}

export function Search() {
  const { t } = useTranslation();
  const [searchResultState, setSearchResultState] = useState(
    SearchResultState.Loading
  );
  useScreenTitle(t('nav_links.search'));
  const channelsString = 'assistant.topics';
  const peopleString = 'assistant.people_list';

  const improvedSearchExperienceShortcutsDocuments = useFeatureFlag(
    Feature.IMPROVED_SEARCH_SHORTCUTS_DOCUMENTS
  );

  const { integrationSearchProviders } = useIntegrationSearchProvidersFetcher();
  const enterpriseNavItems: NavItem[] = integrationSearchProviders.map(
    ({ command, label, integration_id }) => {
      return {
        title: label,
        url: `/search/enterprise/${integration_id}/${command}`,
        type: 'enterprise',
      };
    }
  );

  const handleSearchResultState = useCallback(
    (searchResultState: SearchResultState) => {
      setSearchResultState(searchResultState);
    },
    []
  );

  const navItems: NavItem[] = [
    {
      title: t('assistant.overview'),
      url: `/search/overview`,
      type: 'overview',
    },
    {
      title: t('search.types.posts'),
      url: `/search/posts`,
      type: 'posts',
    },
    {
      title: t(channelsString),
      url: `/search/topics`,
      type: 'topics',
    },
    {
      title: t(peopleString),
      url: `/search/people`,
      type: 'people',
    },
  ];

  if (improvedSearchExperienceShortcutsDocuments) {
    navItems.push({
      title: t('search.types.documents'),
      url: `/search/documents`,
      type: 'documents',
    });

    navItems.push({
      title: t('search.types.shortcuts'),
      url: `/search/shortcuts`,
      type: 'shortcuts',
    });
  }

  return (
    <Page className="search">
      <SearchQueryParamsProvider>
        <SecondarySearchNav navItems={[...navItems, ...enterpriseNavItems]} />
        <PageLayout>
          <Switch>
            <Route path="/search/posts">
              <ContentSearch searchReturnedResults={handleSearchResultState} />
            </Route>
            <Route path={'/search/topics'}>
              <TopicSearch searchReturnedResults={handleSearchResultState} />
            </Route>
            <Route path="/search/people">
              <PeopleSearch searchReturnedResults={handleSearchResultState} />
            </Route>
            <Route path="/search/overview">
              <Overview searchReturnedResults={handleSearchResultState} />
            </Route>
            {improvedSearchExperienceShortcutsDocuments && (
              <Route path="/search/documents">
                <DocumentSearch
                  searchReturnedResults={handleSearchResultState}
                />
              </Route>
            )}
            {improvedSearchExperienceShortcutsDocuments && (
              <Route path="/search/shortcuts">
                <ShortcutSearch
                  searchReturnedResults={handleSearchResultState}
                />
              </Route>
            )}
            <Route path="/search/enterprise/:integrationId/:command">
              <SearchEnterprise
                searchReturnedResults={handleSearchResultState}
              />
            </Route>
          </Switch>
        </PageLayout>
      </SearchQueryParamsProvider>
    </Page>
  );
}

function PageLayout({ children }: { children: React.ReactNode }) {
  const searchType = useSearchRoute().searchType;
  const { items, isLoading } = useCategoryMetadata();
  const routeMetadata = items?.get(searchType);
  return (
    <div className={styles.SearchPage}>
      <Container variant="default" className={styles.SearchContainer} fullWidth>
        {searchType !== 'enterprise' && routeMetadata ? (
          isLoading ? (
            <FilterBarSkeleton />
          ) : (
            <FilterBar
              filters={routeMetadata.filters}
              defaultFilters={routeMetadata.defaultFilters}
            />
          )
        ) : null}
        {children}
      </Container>
    </div>
  );
}

export function SearchQueryParamsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <QueryParamProvider
      adapter={ReactRouter5Adapter}
      options={{
        params: searchQueryParams,
        includeAllParams: true,
      }}
    >
      {children}
    </QueryParamProvider>
  );
}
