import React, { FC, MouseEventHandler, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchQueryParams } from '../query-params';
import { usePeopleSearch } from '../people/people';
import { useTopicSearch } from '../topics/topics-search';
import styles from './overview.module.scss';
import PeopleShortList from '.././search-components/people-short-list';
import TopicsShortList from '.././search-components/topics-short-list';
import DocumentsShortList from '../search-components/documents-short-list';
import ShortcutsShortList from '../search-components/shortcuts-short-list';
import { Container, Icon, Button } from '@socialchorus/shared-ui-components';
import { LoadingSkeleton } from '../../loading-skeleton';
import { useTrendingSearchesFetcher } from '../../../search-bar/search-bar';
import useFeatureFlag from '../../../../common/use-feature-flag';
import { Feature } from '../../../../models/features/features';
import { Button as NonComponentButton } from '../../../ui';
import { ContentSearch, useContentSearch } from '../content/content';
import { SearchResultState, searchTypes } from '../search-screen';
import { useHistory, useLocation } from 'react-router';
import { useLinkNavigation } from '../../../../common/use-link-navigation';
import {
  trackSearchSuccess,
  trackSearchError,
  trackSearchLoad,
  trackTrendingSearchClick,
  trackSearchPreviewResultClick,
} from '../../../../models/analytics';
import { useDocumentSearch } from '../documents/documents';
import { useShortcutSearch } from '../shortcuts/shortcuts';
import { useIsAssistantActive } from '../../../../common/useIsAssistantActive';

interface OverviewSearchErrorProps {
  query: string;
  handleRetry: MouseEventHandler<HTMLButtonElement>;
}

interface OverviewProps {
  searchReturnedResults?: (searchResultState: SearchResultState) => void;
}
interface NoResultsProps {
  query: string;
  trendingSearchTerms: { program_id: string; trending_searches: string[] };
}

const Overview: FC<OverviewProps> = ({ searchReturnedResults }) => {
  const isAssistantActive = useIsAssistantActive();
  const [query] = useSearchQueryParams({
    disabled: isAssistantActive,
  });
  const [prevQuery, setPrevQuery] = useState(query);

  if (query !== prevQuery) {
    setPrevQuery(query);
  }

  const {
    peopleData,
    isLoadingMore: isLoadingPeople,
    error: peopleError,
    mutate: retryPeople,
  } = usePeopleSearch(query);

  const {
    topicsData,
    isLoadingMore: isLoadingTopics,
    error: topicError,
    mutate: retryTopics,
  } = useTopicSearch(query);

  const {
    contentData,
    error: contentError,
    isLoadingMore: isLoadingContent,
    retryContent,
  } = useContentSearch(query);

  const {
    documentsData,
    error: documentsError,
    isLoadingMore: isLoadingDocuments,
    mutate: retryDocuments,
  } = useDocumentSearch(query);

  const {
    shortcutsData,
    error: shortcutsError,
    isLoadingMore: isLoadingShortcuts,
    mutate: retryShortcuts,
  } = useShortcutSearch(query);

  const improvedSearchExperience = useFeatureFlag(Feature.IMPROVED_SEARCH);
  const improvedSearchExperienceShortcutsDocuments = useFeatureFlag(
    Feature.IMPROVED_SEARCH_SHORTCUTS_DOCUMENTS
  );
  const programTrendingSearches = useTrendingSearchesFetcher(
    improvedSearchExperience
  );
  const isLoading = isLoadingPeople || isLoadingContent || isLoadingTopics;

  const noDataReturned =
    !isLoading &&
    (contentData ? contentData?.[0]?.data.length < 1 : true) &&
    (peopleData ? peopleData?.[0]?.data?.length < 1 : true) &&
    (topicsData ? topicsData?.[0]?.data?.length < 1 : true) &&
    (documentsData ? documentsData?.[0]?.data?.length < 1 : true) &&
    (shortcutsData ? shortcutsData?.[0]?.data?.length < 1 : true);

  useEffect(() => {
    // Prevents this being called continuously as rerendering occurs
    trackSearchLoad();
  }, []);

  useEffect(() => {
    if (
      contentError ||
      topicError ||
      peopleError ||
      documentsError ||
      shortcutsError
    ) {
      trackSearchError();
    } else {
      trackSearchSuccess();
    }
  }, [contentError, topicError, peopleError, documentsError, shortcutsError]);

  if (searchReturnedResults) {
    if (
      isLoading ||
      (contentError &&
        topicError &&
        peopleError &&
        documentsError &&
        shortcutsError)
    ) {
      searchReturnedResults(SearchResultState.Loading);
    } else if (!noDataReturned) {
      searchReturnedResults(SearchResultState.HasResults);
    } else {
      searchReturnedResults(SearchResultState.Empty);
    }
  }

  function retryAllSearches() {
    if (searchReturnedResults) {
      searchReturnedResults(SearchResultState.Loading);
    }
    retryPeople();
    retryContent();
    retryTopics();
    retryDocuments();
    retryShortcuts();
  }

  return (
    <div>
      {contentError &&
        topicError &&
        peopleError &&
        documentsError &&
        shortcutsError && (
          <CompleteError
            query={query.query ? query.query : ''}
            handleRetry={retryAllSearches}
          />
        )}
      {noDataReturned &&
        !(
          contentError &&
          topicError &&
          peopleError &&
          documentsError &&
          shortcutsError
        ) &&
        !isLoadingPeople &&
        !isLoadingContent &&
        !isLoadingTopics &&
        !isLoadingDocuments &&
        !isLoadingShortcuts && (
          <NoResultsFoundOverview
            query={query.query ? query.query : ''}
            trendingSearchTerms={programTrendingSearches.trendingSearches}
          />
        )}
      {!noDataReturned &&
        !(
          contentError &&
          topicError &&
          peopleError &&
          documentsError &&
          shortcutsError
        ) && (
          <div className={styles.Container}>
            <div
              className={styles.ContentContainer}
              onClick={() => trackSearchPreviewResultClick()}
            >
              <ContentSearch />
            </div>
            <div className={styles.ListsContainer}>
              {isLoadingPeople ? (
                <PeopleListLoadingSkeleton />
              ) : (
                <PeopleShortList
                  peopleData={peopleData ? peopleData : []}
                  error={Boolean(peopleError)}
                  viewAllUrl={`/search/people`}
                  searchType={`people`}
                />
              )}
              {improvedSearchExperienceShortcutsDocuments &&
                (isLoadingDocuments ? (
                  <DocumentListLoadingSkeleton />
                ) : (
                  <DocumentsShortList
                    documentsData={documentsData ? documentsData : []}
                    error={Boolean(documentsError)}
                    viewAllUrl={`/search/documents`}
                    searchType={`documents`}
                  />
                ))}
              {isLoadingTopics ? (
                <TopicListLoadingSkeleton />
              ) : (
                <TopicsShortList
                  topicData={topicsData ? topicsData : []}
                  error={Boolean(topicError)}
                  viewAllUrl={`/search/topics`}
                  searchType={`topics`}
                />
              )}
              {improvedSearchExperienceShortcutsDocuments && (
                <ShortcutsShortList
                  shortcutsData={shortcutsData ? shortcutsData : []}
                  error={Boolean(shortcutsError)}
                  viewAllUrl={`/search/shortcuts`}
                  searchType={`shortcuts`}
                />
              )}
            </div>
          </div>
        )}
    </div>
  );
};

function DocumentListLoadingSkeleton() {
  const { t } = useTranslation();
  return (
    <Container
      shadow="none"
      shape="large"
      variant="default"
      className={styles.LoadingContainer}
    >
      <div className={styles.LoadingTitleContainer}>
        <span className={`${styles.LoadingHeading}  ${styles.Title}`}>
          {t(`search.types.documents`)}
        </span>
      </div>
      <DocumentLoadingElement />
      <DocumentLoadingElement />
      <DocumentLoadingElement />
    </Container>
  );
}

function DocumentLoadingElement() {
  return (
    <Container
      shadow="none"
      shape="large"
      variant="default"
      className={styles.LoadingInnerContainer}
    >
      <article className={styles.LoadingCard} aria-label="Loading">
        <div className={styles.LoadingIcon}>
          <LoadingSkeleton
            width="34px"
            height="34px"
            borderRadius="10%"
            marginTop="0"
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'left',
            marginTop: '8px',
            gap: '8px',
          }}
        >
          <LoadingSkeleton width="140px" height="12px" marginTop="0" />
          <LoadingSkeleton width="30px" height="12px" marginTop="0" />
        </div>
      </article>
    </Container>
  );
}

function PeopleListLoadingSkeleton() {
  const { t } = useTranslation();
  return (
    <Container
      shadow="none"
      shape="large"
      variant="default"
      className={styles.LoadingContainer}
    >
      <div className={styles.LoadingTitleContainer}>
        <span className={`${styles.LoadingHeading}  ${styles.Title}`}>
          {t(`assistant.people_list`)}
        </span>
      </div>
      <PeopleLoadingElement />
      <PeopleLoadingElement />
    </Container>
  );
}

function PeopleLoadingElement() {
  return (
    <Container
      shadow="none"
      shape="large"
      variant="default"
      className={styles.LoadingInnerContainer}
    >
      <article className={styles.LoadingCard} aria-label="Loading">
        <div className={styles.LoadingIcon}>
          <LoadingSkeleton
            width="50px"
            height="50px"
            borderRadius="50%"
            marginTop="0"
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            marginTop: '8px',
            gap: '8px',
          }}
        >
          <LoadingSkeleton width="132px" height="12px" marginTop="0" />
          <LoadingSkeleton width="102px" height="12px" marginTop="0" />
        </div>
      </article>
    </Container>
  );
}

function TopicListLoadingSkeleton() {
  const { t } = useTranslation();
  return (
    <Container
      shadow="none"
      shape="large"
      variant="default"
      className={styles.LoadingContainer}
    >
      <div className={styles.LoadingTitleContainer}>
        <span className={`${styles.LoadingHeading}  ${styles.Title}`}>
          {t(`assistant.topics`)}
        </span>
      </div>
      <TopicLoadingElement />
      <TopicLoadingElement />
      <TopicLoadingElement />
    </Container>
  );
}

function TopicLoadingElement() {
  return (
    <Container
      shadow="none"
      shape="large"
      variant="default"
      className={styles.LoadingInnerContainer}
    >
      <article className={styles.LoadingCard} aria-label="Loading">
        <div className={styles.LoadingIcon}>
          <LoadingSkeleton
            width="34px"
            height="34px"
            borderRadius="10%"
            marginTop="0"
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            marginTop: '8px',
            gap: '8px',
          }}
        >
          <LoadingSkeleton width="132px" height="12px" marginTop="0" />
          <LoadingSkeleton width="102px" height="12px" marginTop="0" />
        </div>
      </article>
    </Container>
  );
}

function NoResultsFoundOverview({
  query,
  trendingSearchTerms,
}: NoResultsProps) {
  const { t } = useTranslation();
  const { trending_searches } = trendingSearchTerms;
  const history = useHistory();
  const { pathname } = useLocation();

  const handleSearchTermClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    searchTerm: string
  ) => {
    e.preventDefault();
    handleSearch(searchTerm);
    trackTrendingSearchClick({ search_term: searchTerm });
  };

  const handleSearchTermKeyDown = (
    e: React.KeyboardEvent<HTMLButtonElement>,
    searchTerm: string
  ) => {
    if (e.key === 'Enter') {
      handleSearch(searchTerm);
    }
  };

  const handleSearch = (searchTerm: string) => {
    let baseUrl = '';
    const safeTerm = searchTerm.replace(/[%}{}]/g, '').trim();
    const encodedTerm = safeTerm ? encodeURIComponent(safeTerm) : null;
    if (!encodedTerm) {
      return;
    }
    const match = pathname.match(
      new RegExp(`/search/(${searchTypes.join('|')})(/.*)?`)
    );
    if (match) {
      baseUrl = match[0];
    } else {
      baseUrl = '/search/overview';
    }
    history.push(`${baseUrl}?query=${encodedTerm}`);
    return;
  };

  return (
    <Container
      shadow="none"
      shape="large"
      variant="default"
      className={styles.EmptyFeedContainer}
    >
      <div className={styles.EmptyFeedImage}>
        <img src="/images/feed-empty.svg" alt="Empty feed" id="empty-feed" />
      </div>
      <div className={styles.EmptyFeedText}>
        <h2 className={`${styles.ResultsHeadingStart} ${styles.Text}`}>
          {t(`search.overview.results_start`)}
          <span className={`${styles.ResultsHeadingEnd} ${styles.Text}`}>
            {t('search.overview.results_end', { query })}
          </span>
        </h2>
        <p className={styles.MarginBottom}>
          <span className={`${styles.DescriptionDarker} ${styles.Text}`}>
            {t(`search.overview.results_main`)}
          </span>
        </p>
        <p>
          <span className={`${styles.DescriptionDarker} ${styles.Text}`}>
            {t(`search.overview.results_hint`)}
          </span>
          <ul>
            <li className={`${styles.BulletItem} ${styles.Text}`}>
              &#8226; {t(`search.overview.suggestion_one`)}
            </li>
            <li className={`${styles.BulletItem} ${styles.Text}`}>
              &#8226; {t(`search.overview.suggestion_two`)}
            </li>
            <li className={`${styles.BulletItem} ${styles.Text}`}>
              &#8226; {t(`search.overview.suggestion_three`)}
            </li>
          </ul>
        </p>
        {trending_searches && trending_searches.length > 0 && (
          <>
            <hr className={styles.LineBreak} />
            <p className={styles.MarginBottom}>
              <span className={`${styles.DescriptionDarker} ${styles.Text}`}>
                {t(`search.overview.popular_searches`)}
              </span>
            </p>
            <div className="search-terms-pills-container">
              {trending_searches.slice(0, 4).map((search, index) => (
                <NonComponentButton
                  key={`${search}`}
                  shape="pill"
                  size="small"
                  className={styles.SearchTermsPill}
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                    handleSearchTermClick(e, search)
                  }
                  onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) =>
                    handleSearchTermKeyDown(e, search)
                  }
                  aria-label={search}
                >
                  {search.length > 18 ? `${search.slice(0, 18)}...` : search}
                </NonComponentButton>
              ))}
            </div>
          </>
        )}
      </div>
    </Container>
  );
}

export function CompleteError({
  query,
  handleRetry,
}: OverviewSearchErrorProps) {
  const { t } = useTranslation();
  const getLinkNavigation = useLinkNavigation();
  return (
    <Container
      shadow="none"
      shape="large"
      variant="default"
      className={styles.ErrorContainer}
    >
      <div
        className={`${styles.InnerErrorContainer} ${styles.ErrorState}`}
        role="alert"
        aria-live="polite"
      >
        <div style={{ textAlign: 'center' }}>
          <Icon className={styles.WarningIcon} size={109}>
            warning
          </Icon>
        </div>
        <div style={{ textAlign: 'left' }}>
          <h2 className={`${styles.ErrorHeading} ${styles.Text}`}>
            {t('search.error.overview_error')}
          </h2>
          <p className={styles.Paragraph}>
            <span className={`${styles.ErrorDescription} ${styles.Text}`}>
              {t('search.error.overview_error_description', { query })}
            </span>
          </p>
        </div>
        <div style={{ textAlign: 'center' }}>
          <Button
            variant="outline"
            label={t(`search.error.overview_button_home`)}
            href={getLinkNavigation('/')}
            className={styles.ButtonHome}
          />
          <Button
            variant="primary"
            label={t(`search.error.overview_button_retry`)}
            onClick={handleRetry}
            className={styles.ButtonRetry}
          />
        </div>
      </div>
    </Container>
  );
}

export default Overview;
