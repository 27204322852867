import { useState, useMemo, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';

import {
  submissionChannelIds,
  submissionContributorChannels,
  submissionPublishableChannels,
  submissionSubmittableChannels,
} from '../../models/content-submission/atoms';

import useModifiedEffect from '../../common/use-modified-effect';

import { Button as OldButton, Icon, TextInput } from '../../components/ui';
import { Button } from '@socialchorus/shared-ui-components';

import ChannelList from './channel-list';
import { useSelector } from 'react-redux';
import { Feature, getFeatureFlag } from '../../models/features/features';

import { escapeRegExp } from '../../lib/string-utils';
import useFeatureFlag from '../../common/use-feature-flag';
import classNames from 'classnames';

const ChannelsPopover = ({ id, onClose }) => {
  const [selectedChannelIds, setSelectedChannelIds] =
    useRecoilState(submissionChannelIds);
  const contributorChannels = useRecoilValue(submissionContributorChannels);
  const submittableChannels = useRecoilValue(submissionSubmittableChannels);
  const publishableChannels = useRecoilValue(submissionPublishableChannels);

  const tescoQ3 = useFeatureFlag(Feature.TESCO_Q3);

  const sortSelected = (channels) =>
    [...channels].sort(
      (a, b) =>
        selectedChannelIds.includes(b.id) - selectedChannelIds.includes(a.id)
    );

  const filterSelected = (channels) =>
    channels.filter((c) => selectedChannelIds.includes(c.id));

  const { t } = useTranslation();

  // Resort channels, showing selected first
  const [filteredContributorChannels, setFilteredContributorChannels] =
    useState(sortSelected(contributorChannels));
  const [filteredSubmittableChannels, setFilteredSubmittableChannels] =
    useState(sortSelected(submittableChannels));
  const [filteredPublishableChannels, setFilteredPublishableChannels] =
    useState(sortSelected(publishableChannels));

  const [channelFilter, setChannelFilter] = useState();

  const selectedSubmittableChannels = useMemo(
    () => filterSelected(submittableChannels),
    [filterSelected, submittableChannels]
  );
  const selectedPublishableChannels = useMemo(
    () => filterSelected(publishableChannels),
    [filterSelected, publishableChannels]
  );
  const selectedContributorChannels = useMemo(
    () => filterSelected(contributorChannels),
    [contributorChannels, filterSelected]
  );

  const selectedChannelsLength = selectedChannelIds.length;

  const handleSearchChange = (e) => {
    setChannelFilter(e.target.value);
  };

  const handleClearAll = () => {
    setSelectedChannelIds([]);
  };

  useModifiedEffect(
    { skipMount: true },
    () => {
      const filter = escapeRegExp(channelFilter) || '';

      const filterSearched = (channels) =>
        channels.filter(
          (c) =>
            c.name.match(new RegExp(filter, 'i')) ||
            (c.description || '').match(new RegExp(filter, 'i'))
        );

      setFilteredSubmittableChannels(filterSearched(submittableChannels));
      setFilteredPublishableChannels(filterSearched(publishableChannels));
      setFilteredContributorChannels(filterSearched(contributorChannels));
    },
    [channelFilter]
  );

  const newTopicsString = useSelector((state) =>
    getFeatureFlag(state, Feature.CHANNELS_TO_TOPICS_ENABLED)
  );
  const publishToChannelString = newTopicsString
    ? 'content_submission.publish_to_topic'
    : 'content_submission.publish_to_channel';
  const searchForChannelsString = newTopicsString
    ? 'content_submission.search_for_topics'
    : 'content_submission.search_for_channels';
  const publishToYourChannelsString = newTopicsString
    ? 'content_submission.publish_to_your_topics'
    : 'content_submission.publish_to_your_channels';
  const nChannelSelectedString = newTopicsString
    ? 'content_submission.n_topic_selected'
    : 'content_submission.n_channel_selected';
  const publishableChannelsString = 'content_submission.publishable_channels';
  const submittableChannelsString = 'content_submission.submittable_channels';

  return (
    <div
      id={id}
      className="content-submission-channels-popover"
      role="dialog"
      aria-labelledby="content-submission-channels--title"
    >
      <div className="content-submission-channels-popover__header">
        <div className="content-submission-channels-popover__header__subheader">
          <div
            id="content-submission-channels--title"
            className="content-submission-channels__title"
          >
            {t(publishToChannelString)}
          </div>
          <OldButton
            aria-controls={id}
            aria-expanded
            theme="icon-only"
            onClick={() => onClose()}
          >
            <Icon>close</Icon>
          </OldButton>
        </div>

        <div
          role="searchbox"
          aria-controls={[
            'contributor-channel-list',
            'publishable-channel-list',
            'submittable-channel-list',
          ].join(' ')}
          aria-label={t(searchForChannelsString)}
          className="content-submission-channels__search"
        >
          <Icon type="search" />

          <TextInput
            value={channelFilter || ''}
            placeholder={t(searchForChannelsString)}
            onChange={handleSearchChange}
          />
        </div>
      </div>

      <div
        className={classNames(
          'content-submission-channels-popover__body',
          tescoQ3 && 'clear-all-spacing'
        )}
      >
        {tescoQ3 && (
          <div className="content-submission-channels-popover__toolbar">
            <Button
              label={t('content_submission.clear_all')}
              variant="text"
              size="compact"
              disabled={!selectedChannelsLength}
              onClick={handleClearAll}
            />
          </div>
        )}
        {contributorChannels.length ? (
          <ChannelListContainer
            idPrefix="contributor"
            label={
              publishableChannels.length || submittableChannels.length
                ? t(publishToYourChannelsString)
                : t(publishableChannelsString)
            }
          >
            {({ idPrefix, onChange }) => (
              <ChannelList
                idPrefix={idPrefix}
                onChange={onChange}
                channels={filteredContributorChannels}
                disabled={
                  !!selectedSubmittableChannels.length ||
                  !!selectedPublishableChannels.length
                }
              />
            )}
          </ChannelListContainer>
        ) : null}

        {publishableChannels.length ? (
          <ChannelListContainer
            idPrefix="publishable"
            label={t(publishableChannelsString)}
          >
            {({ idPrefix, onChange }) => (
              <ChannelList
                idPrefix={idPrefix}
                onChange={onChange}
                channels={filteredPublishableChannels}
                disabled={!!selectedContributorChannels.length}
              />
            )}
          </ChannelListContainer>
        ) : null}
        {submittableChannels.length ? (
          <ChannelListContainer
            idPrefix="submittable"
            label={t(submittableChannelsString)}
          >
            {({ idPrefix, onChange }) => (
              <ChannelList
                idPrefix={idPrefix}
                onChange={onChange}
                channels={filteredSubmittableChannels}
                disabled={!!selectedContributorChannels.length}
              />
            )}
          </ChannelListContainer>
        ) : null}
      </div>

      <div className="content-submission-channels-popover__footer">
        <div role="status" className="content-submission-channels__status">
          {t(nChannelSelectedString, {
            count: selectedChannelsLength,
          })}
        </div>
      </div>
    </div>
  );
};

const ChannelListContainer = ({ idPrefix, label, children }) => {
  const ref = useRef();
  const [activeDescendantId, setActiveDescendantId] = useState();

  const handleKeyDown = (e) => {
    if (document.activeElement !== ref.current) {
      return;
    }

    if (e.key === 'ArrowUp') {
      const channels = ref.current?.getElementsByClassName(
        'content-submission-channel'
      );

      channels.length > 0 && channels[channels.length - 1]?.focus();
    }

    if (e.key === 'ArrowDown') {
      const channels = ref.current?.getElementsByClassName(
        'content-submission-channel'
      );

      channels.length > 0 && channels[0]?.focus();
    }
  };

  return (
    <div
      ref={ref}
      id={`${idPrefix}-channel-list`}
      role="listbox"
      tabIndex={0}
      onKeyDown={handleKeyDown}
      aria-multiselectable
      aria-labelledby={`${idPrefix}-channel-list--label`}
      aria-activedescendant={activeDescendantId}
    >
      <div
        id={`${idPrefix}-channel-list--label`}
        className="content-submission-channels__subtitle"
      >
        {label}
      </div>
      {children({ idPrefix, onChange: setActiveDescendantId })}
    </div>
  );
};

export default ChannelsPopover;
