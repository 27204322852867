import React, { MouseEventHandler, useEffect, useRef, useState } from 'react';
import { ContentFrame, ContentLink } from '../../content';
import { trackContentCardView } from '../../../models/content/analytics';
import ContentCardTitle from './content-card-title/content-card-title';
import ContentCardDescription from './content-card-description/content-card-description';
import ContentCardToolbar from './content-card-toolbar/content-card-toolbar';
import ContentCardThumbnail from './content-card-thumbnail/content-card-thumbnail';
import ContentTopics from '../content-topics';
import ContentCardSourceInfo from './content-card-source-info/content-card-source-info';
import useInview from '../../../common/use-inview';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import cx from 'classnames';
import ContentCardTranslateButton from './content-card-translate-button/content-card-translate-button';
import {
  getContentIsProcessing,
  getContentIsPublished,
} from '../../../models/content/selectors';
import { Icon, VideoPlayIcon } from '../../ui';
import { Feature } from '../../../models/features/features';
import { usePatronSelector } from '../../../common/use-patron-selector';
import useRandomId from '../../../common/use-random-id';
import { Content } from '../../../models/content/types';
import { useTranslation } from 'react-i18next';
import ContentActionTranslateWrapper from '../content-actions/content-action-translate-wrapper';
import useFeatureFlag from '../../../common/use-feature-flag';

type IContentCard = {
  content: Content;
  analyticsData: IAnalyticsData;
  toolbarMax?: number;
  titleTag?: keyof HTMLElementTagNameMap;
  shouldTrackView?: boolean;
  inlineVideo?: boolean;
  compact?: boolean;
  largeTitleFont?: boolean;
  descriptionMaxLinebreaks?: number;
};

type IContentCardInternal = {
  type: 'horizontal' | 'vertical';
} & IContentCard;

const ContentCardInternal: React.FC<IContentCardInternal> = ({
  content,
  analyticsData,
  type = 'horizontal',
  toolbarMax = 3,
  titleTag = 'div',
  shouldTrackView = true,
  inlineVideo = false,
  compact = false,
  largeTitleFont,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const useFillCrop = useFeatureFlag(Feature.TEST_NEW_UI_FILL_CROP);
  const topicPagesEnabled = useFeatureFlag(Feature.TOPIC_LANDING_PAGES_ENABLED);
  const params = useParams<{ channelId?: string }>();
  const isTopicPage =
    location.pathname.includes('/channels/') && topicPagesEnabled;
  const channelId = params.channelId;
  const isPinnedAndInTopicPage =
    content.pinned &&
    isTopicPage &&
    channelId &&
    content.pinned_channels.includes(Number(channelId));
  const thumbnailLabel = isPinnedAndInTopicPage
    ? t('content.pinned')
    : content.buttons?.[0]?.buttonText;
  const ref = useRef<HTMLDivElement>(null);
  const isVideo = content.content_type === 'video';
  const [showVideo, setShowVideo] = useState<boolean>(false);
  const history = useHistory();
  const { id } = useRandomId();

  const contentImage = () => {
    const preferred_url =
      useFillCrop && !isVideo
        ? content.feed_preview_image_urls?.[0]
        : content.background_image_urls?.[0]?.url;

    return preferred_url || content.background_image_url;
  };

  const className = cx(`content-card content-card-${type}`, {
    'content-card-compact': compact,
  });

  const handleView = () => {
    if (shouldTrackView) trackContentCardView(content.id, analyticsData);
  };

  useInview({
    ref,
    once: true,
    onInview: handleView,
  });

  const handlePlayButtonClick: MouseEventHandler = (e) => {
    //on the overlay and also the button, to make accessibility work easiest
    e.stopPropagation();
    e.preventDefault();
    setShowVideo(true);
  };

  // Reset video state on location change
  useEffect(() => {
    let historyUnlisten;

    if (inlineVideo && showVideo) {
      historyUnlisten = history.listen(() => {
        setShowVideo(false);
      });
    }
    return historyUnlisten;
  }, [showVideo]); // eslint-disable-line react-hooks/exhaustive-deps

  const isPublished = usePatronSelector((state) =>
    getContentIsPublished(state, { contentId: content.id })
  );
  const isProcessing = usePatronSelector((state) =>
    getContentIsProcessing(state, { contentId: content.id })
  );

  const thumbnailImageProperties = () => {
    if (
      !!content.background_image_urls &&
      content.background_image_urls?.length > 1
    ) {
      return {
        contentType: 'multi-image',
        contentLength: content.background_image_urls.length,
      };
    }
    if (content.content_type === 'note') {
      return {
        contentType: 'note',
        backgroundColor: content.border_color,
      };
    }
    if (isVideo) {
      return {
        contentType: 'video',
        contentLength: content.video?.duration,
      };
    }

    return undefined;
  };

  const contentTopics = content.content_channels ? (
    <ContentTopics
      contentId={content.id}
      topics={content.content_channels}
      analyticsData={analyticsData}
    />
  ) : (
    <div className={'content-card__spacer'} />
  );

  return (
    <article
      className={className}
      ref={ref}
      lang={content.language}
      aria-labelledby={`${id}--title`}
      aria-describedby={`${id}--description`}
    >
      <div className={'content-card__header'}>
        <ContentLink
          contentId={content.id}
          ignoreLink={!isPublished || isProcessing}
          analyticsData={analyticsData}
          aria-hidden={true}
          classes={'content-card__thumbnail-container'}
        >
          <div tabIndex={-1}>
            <ContentCardThumbnail
              style={{ height: 'auto' }}
              imgSrc={contentImage()}
              imgSizing={'fill'}
              label={thumbnailLabel}
              labelIcon={
                isPinnedAndInTopicPage ? (
                  <Icon type="push_pin" size="base" />
                ) : undefined
              }
              contentProperties={thumbnailImageProperties()}
            />

            {inlineVideo && isVideo && !showVideo ? (
              <div
                className="content-card__video-play-overlay"
                onClick={handlePlayButtonClick}
              >
                <VideoPlayIcon />
              </div>
            ) : null}

            {showVideo ? (
              <ContentFrame
                contentId={content.id}
                analyticsData={analyticsData}
                autoPlayVideo={true} //autoplay on as this will only be rendered by a user clicking on the overlay
              />
            ) : null}
          </div>
        </ContentLink>
        <div className={'content-card__sub-header'}>
          <div>
            <ContentLink
              contentId={content.id}
              ignoreLink={!isPublished || isProcessing}
              analyticsData={analyticsData}
              followNestedLink
            >
              <div className={'content-card__title'}>
                <ContentCardTitle
                  id={`${id}--title`}
                  contentId={content.id}
                  titleTag={titleTag}
                  maxLinebreaks={compact ? 2 : 3}
                  largeFont={largeTitleFont}
                />
              </div>
              <div className={'content-card__description'}>
                <ContentCardDescription
                  id={`${id}--description`}
                  contentId={content.id}
                  maxLinebreaks={compact ? 2 : 5}
                />
              </div>
            </ContentLink>
            <ContentActionTranslateWrapper
              contentId={content.id}
              analyticsData={analyticsData}
            >
              {({ canTranslate, handleClick, isTranslated }) => (
                <ContentCardTranslateButton
                  isTranslated={isTranslated}
                  canTranslate={canTranslate}
                  handleClick={handleClick}
                />
              )}
            </ContentActionTranslateWrapper>
          </div>

          {type === 'horizontal' && contentTopics}
        </div>
      </div>

      {type === 'vertical' && (
        <div className={'content-card__body'}>{contentTopics}</div>
      )}

      <div className={'content-card__footer'}>
        <ContentCardSourceInfo contentId={content.id} />
        <div
          role="separator"
          aria-orientation="vertical"
          className="content-card__footer-divider"
        />
        <ContentCardToolbar
          content={content}
          max={toolbarMax}
          wideMode={type === 'vertical' && !compact}
          analyticsData={analyticsData}
        />
      </div>
    </article>
  );
};

const ContentCardVertical: React.FC<IContentCard> = ({
  content,
  analyticsData,
  toolbarMax = 3,
  inlineVideo,
  shouldTrackView,
  titleTag,
  largeTitleFont,
  compact,
}) => {
  return (
    <ContentCardInternal
      content={content}
      analyticsData={analyticsData}
      toolbarMax={toolbarMax}
      type={'vertical'}
      inlineVideo={inlineVideo}
      shouldTrackView={shouldTrackView}
      titleTag={titleTag}
      compact={compact}
      largeTitleFont={largeTitleFont}
    />
  );
};

const ContentCardHorizontal: React.FC<IContentCard> = ({
  content,
  analyticsData,
  toolbarMax = 2,
  inlineVideo,
  shouldTrackView,
  titleTag,
  compact,
  largeTitleFont,
}) => {
  return (
    <ContentCardInternal
      content={content}
      analyticsData={analyticsData}
      toolbarMax={toolbarMax}
      type={'horizontal'}
      inlineVideo={inlineVideo}
      shouldTrackView={shouldTrackView}
      titleTag={titleTag}
      compact={compact}
      largeTitleFont={largeTitleFont}
    />
  );
};

export type ContentCardProps = Pick<
  IContentCard,
  | 'content'
  | 'analyticsData'
  | 'toolbarMax'
  | 'inlineVideo'
  | 'shouldTrackView'
  | 'titleTag'
  | 'compact'
  | 'largeTitleFont'
>;

export const ContentCard = {
  Vertical: ContentCardVertical,
  Horizontal: ContentCardHorizontal,
};
