import { createApiClient } from '../../../config/base-api';
import { ResourceObject } from '../../../lib/types/json-api';
import { Program } from '../../../models/program/types';
import { Filter } from './filter-bar/filters/filters';
import { SearchParams } from './query-params';
import { SearchType } from './search-screen';

/**
 * Optimus API client
 */
export const optimus = createApiClient();

export type CategoryMetadata = {
  name: SearchType;
  defaultFilters: string[];
  filters: Filter[];
};

/**
 * Fetches search metadata for a given program
 * @param programId
 */
export async function getSearchMetadata(programId: Program['id']) {
  return optimus
    .get<{
      categories: CategoryMetadata[];
    }>(`/v1/programs/${programId}/search/meta`)
    .then((response) => response.data);
}

export const createCacheKey =
  (type: SearchType) => (searchParams: SearchParams) => {
    return JSON.stringify({
      type: type,
      params: searchParams,
    });
  };

export const parseCacheKey = (cacheKey: string) => {
  return JSON.parse(cacheKey) as { type: SearchType; params: SearchParams };
};

type FilterOptionsResponse = {
  data: ResourceObject<'', { id: number; name: string }>[];
};

export async function queryFilterOptionSource(
  query: string,
  { url, page, perPage }: { url: string; page: number; perPage: number }
) {
  return optimus
    .get<FilterOptionsResponse>(url, {
      params: { q: query, page, perPage },
    })
    .then((res) => res.data);
}
