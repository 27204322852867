import { createSelector } from 'reselect';

import { programSelectors } from '../program';

import { profileSelectors } from '../profile';
import { RootPatronState } from '../../common/use-patron-selector';

const advocate = (state: RootPatronState) => state.advocate || {};
const publishSettings = (state: RootPatronState) =>
  state.advocate.publishingConfig;

const getAdvocate = createSelector([advocate], (advocate) => advocate || {});

const getAdvocateId = createSelector([getAdvocate], (advocate) => advocate.id);

const getAdvocateFederatedId = createSelector(
  [getAdvocate],
  (advocate) => advocate.federatedIdentifier
);

const getAdvocateRole = createSelector(
  [getAdvocate],
  (advocate) => advocate.role
);

const getAdvocateIsAdmin = createSelector(
  [getAdvocateRole],
  (role) =>
    role === 'super_admin' ||
    role === 'administrator' ||
    role === 'program_admin' ||
    role === 'publisher'
);

const getAdvocateIsAnalyst = createSelector(
  [getAdvocateRole],
  (role) => role === 'analyst'
);

const getAdvocateIsContributor = createSelector(
  [getAdvocateRole],
  (role) => role === 'channel_contributor'
);

const getAdvocateIsMember = createSelector(
  [getAdvocateRole],
  (role) => role === 'member'
);

const getAdvocateEmail = createSelector(
  [getAdvocate],
  (advocate) => advocate.email
);

const getAdvocateEntitlements = createSelector(
  [getAdvocate],
  (advocate) => advocate.entitlements || []
);

const getCanShareSubmission = createSelector(
  [publishSettings, programSelectors.getProgramCanShareContent],
  (settings, shareEnabled) =>
    settings.content_settings.includes('share') && shareEnabled
);

const getCanCommentSubmission = createSelector(
  [publishSettings, programSelectors.getProgramCanCommentContent],
  (settings, commentEnabled) =>
    settings.content_settings.includes('comment') && commentEnabled
);

const getCanTranslateSubmission = createSelector(
  [publishSettings, programSelectors.getProgramCanTranslateContent],
  (settings, translateEnabled) =>
    settings.content_settings.includes('translate') && translateEnabled
);

const getCanDraftSubmission = createSelector([publishSettings], (settings) =>
  settings.edit_content.includes('draft')
);

const getCanArchiveSubmission = createSelector([publishSettings], (settings) =>
  settings.edit_content.includes('archive')
);

//
// Delegated to Profile Selectors
//
const getAdvocateName = profileSelectors.getProfileName;
const getAdvocateAvatarUrl = profileSelectors.getProfileAvatarUrl;
const getAdvocateAvatarColor = profileSelectors.getProfileAvatarColor;
const getAdvocateIsPrivate = profileSelectors.getProfileIsPrivate;
const getAdvocateNameMissing = profileSelectors.getProfileNameMissing;

export default {
  getAdvocate,
  getAdvocateId,
  getAdvocateFederatedId,
  getAdvocateRole,
  getAdvocateIsAdmin,
  getAdvocateIsAnalyst,
  getAdvocateIsContributor,
  getAdvocateIsMember,
  getAdvocateEmail,
  getAdvocateName,
  getAdvocateAvatarUrl,
  getAdvocateAvatarColor,
  getAdvocateIsPrivate,
  getAdvocateNameMissing,
  getAdvocateEntitlements,
  getCanShareSubmission,
  getCanCommentSubmission,
  getCanTranslateSubmission,
  getCanDraftSubmission,
  getCanArchiveSubmission,
};
