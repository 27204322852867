import React, { JSXElementConstructor, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  matchPath,
  Redirect,
  Route,
  Switch,
  withRouter,
  RouteComponentProps,
} from 'react-router-dom';
import { programMembershipSelectors } from './models/program-membership';
import { programSelectors } from './models/program';
import { getLegacyRedirect } from './lib/legacy-routes';
import { isInMSTeams } from './lib/teams-helper';
import Home from './screens/home';
import FilteredFeed from './screens/filtered-feed';
import InitiativeFeed from './screens/initiative-feed';
import ChannelsList from './screens/channels-list';
import Discover from './screens/discover';
import Search from './screens/search';
import Profile from './screens/profile';
import NotFound from './screens/not-found';
import EmailContentDetails from './screens/email-content-details';
import ContentSubmissionNew from './screens/content-submission/content-submission-new';
import ContentSubmissionEdit from './screens/content-submission/content-submission-edit';
import PrivacyPolicy from './screens/privacy-policy';
import TermsOfService from './screens/terms-of-service';
import { Assistant } from './screens/assistant';
import { AssistantButton } from './components/assistant';
import { CommentsWebviewView } from './screens/comments-webview/comments-webview';
import { Feature, getFeatureFlag } from './models/features/features';
import {
  CommentsWebviewCustomUrl,
  ContentCustomUrl,
} from './screens/custom-urls/content-custom-url';
import { ContentDetailsView } from './screens/content-details/content-details-view';
import { Location } from 'history';
import { RootPatronState } from './common/use-patron-selector';
import { addRegexToPath } from './lib/react-router-utils';
import { TopicPageWrapper } from './components/v2/topic-page/topic-page-wrapper';
import { ChannelTabType } from './models/channels/types';
import {
  contentsRoutePaths,
  webviewContentsRoutePaths,
} from './patron-routes-constants';
import { Search as SearchImprovements } from './components/v2/search/search-screen';
import MessageDetails from './screens/message-details/message-details';
import { BoxPreview } from './screens/box-integration/BoxPreview';
import { ContentExplorer } from './screens/box-integration/ContentExplorer';

export const numericContentsRoutePaths = contentsRoutePaths.map((path) =>
  addRegexToPath(path, ':id', 'numerical')
);
export const alphanumericContentsRoutePaths = contentsRoutePaths.map((path) =>
  addRegexToPath(path, ':id', 'alphanumericAtleastOneNonNumeric')
);

const numericWebviewContentsRoutePaths = webviewContentsRoutePaths.map((path) =>
  addRegexToPath(path, ':id', 'numerical')
);
const alphanumericWebviewContentsRoutePaths = webviewContentsRoutePaths.map(
  (path) => addRegexToPath(path, ':id', 'alphanumericAtleastOneNonNumeric')
);

type LocationProp = {
  location: Location;
};

const ScrollToTop = ({ location }: LocationProp) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
};

const LegacyRedirect = ({ location }: LocationProp) => {
  const route = getLegacyRedirect(location.hash);
  return <Redirect to={route} />;
};

type PatronRoutesProps = RouteComponentProps &
  ReturnType<typeof mapStateToProps>;

class PatronRoutes extends React.Component<PatronRoutesProps> {
  contentDetails = this.props.isEmailOnlyProgram
    ? EmailContentDetails
    : ContentDetailsView;

  overlayRoutes = [
    {
      path: '/assistant',
      component: Assistant, // Assistant component handles subpages within assistant
      goBackHome: true,
      exact: false,
    },
  ] as const;
  primaryLocation: any;

  constructor(props: PatronRoutesProps) {
    super(props);

    const currentPathname = this.props.location.pathname;
    const currentLocation =
      currentPathname + this.props.location.search + this.props.location.hash;
    const currentState = this.props.location.state;

    this.primaryLocation = this.props.isActiveMembership
      ? this.isOverlayRoute || this.isLegacyRoute
        ? { pathname: '/' }
        : this.props.location
      : this.props.location;

    // If we have no current state, assume this is an initial load.
    // Add home path to history as first route for back purposes
    // If we have a current state, this is a reload instead. Root will already have been added previously.
    // for email-only programs, we want to direct to the 'content not found' page as a default
    // Note: chrome will sometimes ignore these entries when pressing the browser's back button.
    if (
      !this.props.backButtonDisabled &&
      currentPathname !== '/' &&
      !currentState
    ) {
      this.props.history.replace(
        this.props.isEmailOnlyProgram ? this.emailOnlyNoContentPath : '/'
      );
      this.props.history.push(currentLocation);
    }

    if (this.props.isSupportIFrameEnabled) {
      this.rememberLocation();
    }
  }

  componentDidUpdate(prevProps: PatronRoutesProps) {
    if (
      this.props.isSupportIFrameEnabled &&
      this.props.location !== prevProps.location
    ) {
      this.rememberLocation();
    }

    this.setPageScroll(!this.isOverlayRoute);
  }

  render() {
    if (!this.isOverlayRoute && !this.isLegacyRoute)
      this.primaryLocation = this.props.location;

    if (
      this.props.isEmailOnlyProgram &&
      !this.isContentRoute &&
      !this.isLegacyRoute
    )
      this.primaryLocation = { pathname: this.emailOnlyNoContentPath }; // prevent nav to locations in the switch

    return (
      <>
        <ScrollToTop location={this.primaryLocation} />

        <Route path="/(sc4|welcome)" exact component={LegacyRedirect} />
        {!this.isContentRoute &&
        !this.isSubmitRoute &&
        !this.isMessagesRoute &&
        !this.isBoxRoute &&
        !this.props.isEmailOnlyProgram ? (
          <AssistantButton isOpen={this.isAssistantRoute} />
        ) : null}

        <Switch location={this.primaryLocation}>
          <Route path="/feed/:filter([a-z_]+)" exact component={FilteredFeed} />
          <Route
            path={`/channels/:channelId([0-9]+)/:tabType(${Object.values(
              ChannelTabType
            ).join('|')})?`}
            exact
            component={TopicPageWrapper}
          />
          <Route
            path="/initiatives/:initiativeId([0-9]+)"
            exact
            component={InitiativeFeed}
          />
          <Route
            path="/channels/:filter(newest|recommended|popular)"
            exact
            component={ChannelsList}
          />
          <Route path="/channels" exact component={ChannelsList} />

          {/* Search Routes */}
          {/* EE.SearchImprovements.Enabled */}
          {this.props.searchImprovements && (
            <Route
              path="/search/:searchType(enterprise)/:integrationId/:command?"
              component={SearchImprovements}
            />
          )}
          {this.props.searchImprovements && (
            <Route path="/search/:searchType?" component={SearchImprovements} />
          )}
          {/* React-Router doesn't like routes being nested, so we need to handle these individually */}
          {!this.props.searchImprovements && (
            <Route
              path="/search/:searchType(enterprise)/:integrationId/:command/:searchTerm?"
              component={Search}
            />
          )}
          {!this.props.searchImprovements && (
            <Route path="/search/:searchType/:searchTerm" component={Search} />
          )}

          <Route
            path="/account/:page(view|bookmarks|submitted|channels|edit|organization)"
            component={Profile}
          />
          <Redirect
            from="/account/profile"
            to={
              this.props.isAdvancedProfilesEnabled
                ? '/account/view'
                : '/account/submitted'
            }
          />
          <Route
            path="/profiles/:profileId/:page(view|submitted|channels|organization)"
            component={Profile}
          />
          <Redirect
            from="/profiles/:profileId"
            to={
              this.props.isAdvancedProfilesEnabled
                ? '/profiles/:profileId/view'
                : '/profiles/:profileId/submitted'
            }
          />
          <Route path="/discover" component={Discover} />
          <Route path="/policies/privacy" component={PrivacyPolicy} />
          <Route path="/policies/terms" component={TermsOfService} />

          <Route
            path={numericContentsRoutePaths}
            exact
            component={this.contentDetails}
          />

          <Route
            path={addRegexToPath(
              '/messages/:messageId',
              ':messageId',
              'alphanumeric'
            )}
            exact
            component={MessageDetails}
          />

          {this.props.customUrlsEnabled && (
            <Route
              path={alphanumericContentsRoutePaths}
              exact
              component={ContentCustomUrl}
            />
          )}

          <Route
            path={numericWebviewContentsRoutePaths}
            exact
            component={CommentsWebviewView}
          />

          {this.props.customUrlsEnabled && (
            <Route
              path={alphanumericWebviewContentsRoutePaths}
              exact
              component={CommentsWebviewCustomUrl}
            />
          )}

          <Route
            path="/contents/:id/edit"
            exact
            component={ContentSubmissionEdit}
          />

          <Route path="/approvals/:id" exact component={this.contentDetails} />
          <Route
            path="/submit/:contentType"
            exact
            component={ContentSubmissionNew}
          />
          <Route
            path="/feed/:feedItemId([0-9]+)"
            component={this.contentDetails}
          />

          {this.props.boxIntegrationEnabled && (
            <Route
              key="box-preview"
              path="/box/:fileId/preview"
              exact
              component={BoxPreview}
            />
          )}
          {this.props.boxIntegrationEnabled && (
            <Route
              key="box-content-explorer"
              path="/box/:folderId/explore"
              exact
              component={ContentExplorer}
            />
          )}

          {this.props.isActiveMembership ? (
            <Route path="/" exact component={Home} />
          ) : (
            <Route path="/" exact component={FilteredFeed} />
          )}

          <Route path="/_blank" render={() => null} />

          <Route component={NotFound} />
        </Switch>

        {this.overlayRoutes.map(
          ({ path, exact, goBackHome, component: Component }) => (
            <Route
              path={path}
              exact={exact}
              render={(props) => (
                <Component
                  {...props}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  goBack={goBackHome ? this.primaryLocation : null}
                />
              )}
              key={path}
            />
          )
        )}
      </>
    );
  }

  // We save location only for requests from teams as if one user login to another program and then open teams tap app
  // it will receive error as teams will try to connect to another program, so we need to save location only
  // when user is on web app to store specific program location
  // Also it doens't affect native teams app, works correctly
  rememberLocation() {
    if (isInMSTeams) {
      localStorage.setItem('lastLocation', window.location.pathname);
    }
  }

  setPageScroll(enabled = true) {
    const body = document.body;
    const page = body.querySelector('.page');

    if (!page || !(page instanceof HTMLElement)) return;

    if (!enabled) {
      page.style.marginTop = page.getBoundingClientRect().top + 'px';
      body.classList.add('is-overlayed');
    } else {
      // explicitly restore scroll since IE11 doesn't support scroll restoration
      if (window.navigator.userAgent.includes('Trident')) {
        const yOffset =
          body?.querySelector('.site-header')?.getBoundingClientRect().bottom ??
          0;
        const yPos = -1 * parseInt(page.style.marginTop, 10);

        if (yPos) setTimeout(() => window.scrollTo(0, yPos + yOffset), 0);
      }

      page.style.marginTop = '';
      body.classList.remove('is-overlayed');
    }
  }

  get isOverlayRoute() {
    return !!this.overlayRoutes.find(({ path, exact }) =>
      matchPath(this.props.location.pathname, {
        path,
        exact,
      })
    );
  }

  get isLegacyRoute() {
    return matchPath(this.props.location.pathname, {
      path: '/sc4',
      exact: true,
    });
  }

  get isAssistantRoute() {
    return matchPath(this.props.location.pathname, {
      path: '/assistant',
    });
  }

  get isContentRoute() {
    return matchPath(this.props.location.pathname, {
      path: ['/contents/:id', '/approvals/:id'],
    });
  }

  get isBoxRoute() {
    return matchPath(this.props.location.pathname, {
      path: '/box/*',
    });
  }

  get isSubmitRoute() {
    return matchPath(this.props.location.pathname, {
      path: '/submit/*',
    });
  }

  get isMessagesRoute() {
    return matchPath(this.props.location.pathname, {
      path: '/messages/:id',
    });
  }

  get emailOnlyNoContentPath() {
    return '/contents/0';
  }
}

const mapStateToProps = (state: RootPatronState) => ({
  isActiveMembership:
    programMembershipSelectors.getProgramMembershipIsActive(state),
  isEmailOnlyProgram: programSelectors.getEmailTokenAuthEnabled(state),
  isAdvancedProfilesEnabled: programSelectors.getAdvancedProfileEnabled(state),
  isSupportIFrameEnabled: getFeatureFlag(state, Feature.SUPPORT_IFRAME_ENABLED),
  customUrlsEnabled: getFeatureFlag(state, Feature.CUSTOM_URLS_ENABLED),
  searchImprovements: getFeatureFlag(state, Feature.IMPROVED_SEARCH),
  searchImprovementsDocumentsShortcuts: getFeatureFlag(
    state,
    Feature.IMPROVED_SEARCH_SHORTCUTS_DOCUMENTS
  ),
  backButtonDisabled: getFeatureFlag(
    state,
    Feature.CONTENT_BACK_BUTTON_REMOVED
  ),
  boxIntegrationEnabled: getFeatureFlag(state, Feature.BOX_INTEGRATION),
});

export default compose<JSXElementConstructor<PatronRoutesProps>>(
  connect(mapStateToProps, null),
  withRouter
)(PatronRoutes);
