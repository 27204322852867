import React from 'react';
import { DateInput } from '../../../components/ui';

const ProfileDateInput = ({
  name,
  value = '',
  placeholder,
  onChange,
  onBlur,
  disabled,
}) => (
  <DateInput
    name={name}
    value={value || ''}
    placeholder={placeholder}
    onChange={onChange}
    onBlur={onBlur}
    isDisabled={disabled}
  />
);

export default ProfileDateInput;
