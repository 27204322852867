import { ForwardedRef, forwardRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Comments from './comments';
import { DropdownList } from '../../ui';
import { SortOrder } from '../../../common/use-comment-feed';
import { trackContentCardCommentSortClick } from '../../../models/content/analytics';
import { usePatronSelector } from '../../../common/use-patron-selector';
import { ValueType } from '../../../lib/utility-types';

import { contentSelectors } from '../../../models/content/index.js';

import './content-comments.scss';

type ContentCommentsProps = {
  contentId: number;
};

const ContentComments = (
  { contentId }: ContentCommentsProps,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const { commentId } = useParams<{ commentId?: string }>();

  const { t } = useTranslation();

  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.TOP);
  const onSelectionChange = (item: ValueType<typeof commentSortOptions>) => {
    const sort_type = item.sortOrder;
    trackContentCardCommentSortClick(contentId, { sort_type });
    setSortOrder(sort_type);
  };

  const canComment = usePatronSelector((state) =>
    contentSelectors.getContentCanComment(state, { contentId })
  );
  const commentCount = usePatronSelector((state) =>
    contentSelectors.getContentCommentCount(state, { contentId })
  );

  const commentSortOptions = Object.values(SortOrder).map((item) => ({
    name: t(`comments.sort.${item}.name`),
    description: t(`comments.sort.${item}.description`),
    sortOrder: item,
  }));

  const showCommentSort = !commentId;

  return canComment ? (
    <div ref={ref} className="content-info__comments heartsplosion-container">
      <div className="comment-title-container">
        {showCommentSort ? (
          <DropdownList
            options={commentSortOptions}
            onSelectionChange={onSelectionChange}
            disabled={commentCount <= 1}
          />
        ) : null}

        <div className="comments__count">
          {commentCount} {t('content.comment', { count: commentCount })}
        </div>
      </div>

      <Comments
        contentId={contentId}
        sortOrder={sortOrder}
        commentId={commentId ? Number(commentId) : undefined}
      />
    </div>
  ) : null;
};

export default forwardRef(ContentComments);
