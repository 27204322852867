/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  ComponentPropsWithoutRef,
  forwardRef,
  ReactNode,
  useEffect,
} from 'react';
import cx from 'classnames';
import FocusLock from 'react-focus-lock';
import '../ui.scss';
import styles from './styles.module.scss';

type ModalProps = ComponentPropsWithoutRef<'div'> & {
  className?: string;
  transparent?: boolean;
  lockScroll?: boolean;
  children: ReactNode;
  onClose?: () => void;
};
const Modal = forwardRef<HTMLDivElement, ModalProps>(
  (
    { className, transparent, lockScroll, children, onClose, ...props },
    ref
  ) => {
    className = cx(className, styles.modal);

    const containerClassName = cx(styles.modal__container, {
      [styles.modal__containerTransparent]: transparent,
    });

    useEffect(() => {
      if (lockScroll) {
        document.body.classList.add('lock-scroll');

        return () => document.body.classList.remove('lock-scroll');
      }
    }, [lockScroll]);

    const handleKeyPress = (ev: React.KeyboardEvent) => {
      if (ev.code === 'Space' || ev.code === 'Escape') {
        onClose?.();
      }
    };

    return (
      <FocusLock>
        <div
          className={containerClassName}
          onClick={onClose}
          onKeyDown={handleKeyPress}
        >
          <div
            ref={ref}
            className={className}
            onKeyDown={handleKeyPress}
            // hacky way to implement this, but without refactoring too much this should work
            onClick={(e) => e.stopPropagation()}
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex={0}
            role="dialog"
            {...props}
          >
            {children}
          </div>
        </div>
      </FocusLock>
    );
  }
);

export { ModalCloseButton } from './CloseButton';

Modal.displayName = 'UI.Modal';

export default Modal;
