import React, { FC } from 'react';
import { BaseTab } from '../BaseTab';
import { useTranslation } from 'react-i18next';
import useTopicAboutPageFetcher from '../../../../../common/use-topic-about-page-fetcher';
import { ChannelTabType } from '../../../../../models/channels/types';
import useIframeResize from '../../../../../common/useIframeResize';
import { Container } from '@socialchorus/shared-ui-components';
import { Spinner } from '../../../../ui';
import styles from './styles.module.scss';
interface Props {
  topicId: string;
}

const AboutTab: FC<Props> = ({ topicId }) => {
  const { aboutPage, isFetching, isError } = useTopicAboutPageFetcher({
    topicId: topicId,
  });
  const { t } = useTranslation();
  const errorMessage = t('screens.topics.about.error');
  const iframeStyles = `
  body {
    margin: 0;
    padding: 1px 0;
  }
  img {
    max-width: 100%;
  }
`;

  const { height, ref: iframeRef } = useIframeResize({
    styles: iframeStyles,
  });

  return (
    <BaseTab currentTab={ChannelTabType.About} topicId={topicId}>
      <Container className={styles.Container}>
        {isError && !isFetching && <div>{errorMessage}</div>}
        {isFetching && <Spinner />}
        {!isFetching && (
          <iframe
            srcDoc={aboutPage}
            ref={iframeRef}
            title="about-page"
            className={styles.AboutIframe}
            style={height !== undefined ? { height: `${height}px` } : undefined}
            height={height}
            scrolling="no"
          />
        )}
      </Container>
    </BaseTab>
  );
};

export default AboutTab;
