import React from 'react';
import { connect } from 'react-redux';

import { contentSelectors } from '../../../models/content/index.js';
import { programSelectors } from '../../../models/program';

import ActionEdit from '../../../components/content/content-action-edit';
import ActionLike from '../../../components/content/content-action-like';
import ActionShare from '../../../components/content/content-action-share';
import ActionBookmark from '../../../components/content/content-action-bookmark';
import ActionMore from '../../../components/content/content-action-more';
import ActionTranslate from '../../../components/content/content-action-translate';
import ActionAcknowledgement from '../../../components/content/content-action-acknowledgement';

import './content-info.scss';

const ContentInfoActions = ({
  contentId,
  analyticsData,
  emailContent,
  canLike,
  canEdit,
  canShare,
  canBookmark,
  canTranslate,
  canAcknowledge,
  isPublished,
  isPending,
}) => {
  return (
    <div>
      {!emailContent ? (
        <div className="content-info__actions">
          {canLike ? (
            <ActionLike
              contentId={contentId}
              analyticsData={analyticsData}
              hideEmptyCount
            />
          ) : null}

          <div className="content-info__actions--right">
            {canEdit ? (
              <ActionEdit contentId={contentId} analyticsData={analyticsData} />
            ) : null}

            {canBookmark ? (
              <ActionBookmark
                contentId={contentId}
                analyticsData={analyticsData}
              />
            ) : null}

            {canShare ? (
              <ActionShare
                contentId={contentId}
                analyticsData={analyticsData}
              />
            ) : null}

            {canTranslate ? (
              <ActionTranslate
                contentId={contentId}
                analyticsData={analyticsData}
              />
            ) : null}

            {isPublished || isPending ? (
              <ActionMore contentId={contentId} analyticsData={analyticsData} />
            ) : null}
          </div>
        </div>
      ) : null}

      {canAcknowledge ? (
        <div className="content-info__acknowledge">
          <ActionAcknowledgement
            contentId={contentId}
            analyticsData={analyticsData}
          />
        </div>
      ) : null}
    </div>
  );
};
const mapStateToProps = (state, ownProps) => ({
  canLike: contentSelectors.getContentCanLike(state, ownProps),
  canBookmark: contentSelectors.getContentCanBookmark(state, ownProps),
  canShare: contentSelectors.getContentCanShare(state, ownProps),
  canEdit: contentSelectors.getContentCanEdit(state, ownProps),
  isPublished: contentSelectors.getContentIsPublished(state, ownProps),
  isPending: contentSelectors.getContentIsPending(state, ownProps),
  canTranslate: contentSelectors.getContentCanTranslate(state, ownProps),
  canAcknowledge: contentSelectors.getContentCanAcknowledge(state, ownProps),
});

export default connect(mapStateToProps, null)(ContentInfoActions);
