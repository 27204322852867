import { FeedCard as SharedFeedCard } from '@socialchorus/shared-ui-components';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useFeatureFlag from '../../../../../common/use-feature-flag';
import useScreenSize from '../../../../../common/use-screen-size';
import { Feature } from '../../../../../models/features/features';
import { useLinkNavigation } from '../../../../../common/use-link-navigation';
import { LoadingSkeleton } from '../../../loading-skeleton';
export function FeedCardLoading() {
  // Still needs image element added on right side for content / posts
  const useFillCrop = useFeatureFlag(Feature.TEST_NEW_UI_FILL_CROP);
  const getLinkNavigation = useLinkNavigation();
  const { isMobile } = useScreenSize();
  const { t } = useTranslation();

  const ref = useRef<HTMLDivElement>(null);

  return (
    <SharedFeedCard.Root>
      <div className="col-span-7">
        <LoadingSkeleton height={14} />
        <LoadingSkeleton height={14} width="50%" />
        <LoadingSkeleton height={9} marginTop={13} />
        <LoadingSkeleton height={9} width="30%" />
        <LoadingSkeleton height={26} width={164} marginTop={56} />
        <LoadingSkeleton height={24} width={24} marginTop={24} />
      </div>
      <div className="col-span-1"></div>
      <div className="col-span-4">
        <LoadingSkeleton height={200} borderRadius={0} />
      </div>
    </SharedFeedCard.Root>
  );
}
