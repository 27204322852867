import {
  Fragment,
  useState,
  useRef,
  useLayoutEffect,
  useCallback,
} from 'react';
import { connect } from 'react-redux';
import { useEventListener } from 'usehooks-ts';
import { assistantSelectors } from '../../models/assistant';
import {
  trackAssistantInboxClick,
  trackAssistantExploreClick,
  trackAssistantNotificationsHistoryClick,
} from '../../models/assistant/analytics';
import {
  Globe,
  Icon,
  Inbox,
  Notifications,
  Tooltip,
} from '../../components/ui';
import { RootPatronState } from '../../common/use-patron-selector';
import { ValueType } from '../../lib/utility-types';
import { useTranslation } from 'react-i18next';
import { AssistantLink } from '../../components/assistant/assistant-link';

type AssistantNavigationNavItemProps = {
  integration: ValueType<AssistantNavProps['integrations']>;
};

function AssistantIntegrationNavItem({
  integration,
}: AssistantNavigationNavItemProps) {
  const [showTooltip, setShowTooltip] = useState(false);
  const ref = useRef<HTMLAnchorElement>(null);
  const handleMouseOver = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <Fragment key={integration.id}>
      <AssistantLink
        variant="nav"
        className="assistant__nav-link"
        to={{
          pathname: `/assistant/service/${integration.id}`,
          state: { referer: 'menu' },
        }}
        onMouseEnter={handleMouseOver}
        ref={ref}
        onMouseLeave={handleMouseLeave}
        aria-label={integration.subject.title}
      >
        <img
          role="presentation"
          src={integration.subject.icon_inactive_url}
          alt="Inactive Icon"
        />
      </AssistantLink>
      {showTooltip ? (
        <Tooltip attach={ref}>{integration.subject.title}</Tooltip>
      ) : null}
    </Fragment>
  );
}

type AssistantNavProps = {
  integrations: ReturnType<typeof assistantSelectors.getIntegrations>;
};

function AssistantNav({ integrations }: AssistantNavProps) {
  const { t } = useTranslation();

  const [visibleServices, setVisibleServices] = useState(0);
  const [showMore, setShowMore] = useState(false);
  const servicesRef = useRef<HTMLDivElement>(null);

  const handleInboxClick = () => {
    trackAssistantInboxClick();
  };

  const handleExploreClick = () => {
    trackAssistantExploreClick();
  };

  const handleNotificationsClick = () => {
    trackAssistantNotificationsHistoryClick();
  };

  const handleWindowResize = useCallback(() => {
    const { current } = servicesRef;
    if (!current) return;

    const { top } = current.getBoundingClientRect();

    const serviceSize = 60;
    const paneVisible = window.innerHeight - top - 60;

    const integrationsCount = integrations?.length ?? 0;

    const iconFitCount =
      paneVisible > serviceSize ? Math.floor(paneVisible / serviceSize) : 0;
    const more = iconFitCount < integrationsCount;

    const numVisible = Math.floor(more ? iconFitCount - 1 : integrationsCount);

    setVisibleServices(numVisible);
    setShowMore(more);
  }, [integrations]);

  useLayoutEffect(() => {
    handleWindowResize();
  }, [handleWindowResize, integrations]);

  useEventListener('resize', handleWindowResize);

  return (
    <>
      <nav
        aria-label={t('assistant.assistant_navigation.main')}
        className="assistant__nav-icons"
      >
        <AssistantLink
          variant="nav"
          exact
          to="/assistant"
          onClick={handleInboxClick}
          className="assistant__nav-link"
          aria-label={t('assistant.assistant_navigation.landing')}
        >
          <Inbox role="presentation" />
        </AssistantLink>
        <AssistantLink
          variant="nav"
          to="/assistant/commandcenter"
          onClick={handleExploreClick}
          className="assistant__nav-link"
          aria-label={t('assistant.assistant_navigation.command_center')}
        >
          <Globe role="presentation" />
        </AssistantLink>
        <AssistantLink
          variant="nav"
          to="/assistant/notifications"
          onClick={handleNotificationsClick}
          className="assistant__nav-link"
          aria-label={t('assistant.assistant_navigation.notifications')}
        >
          <Notifications role="presentation" />
        </AssistantLink>
        <AssistantLink
          to="/"
          className="assistant__nav-link assisstant__close-link"
          aria-label={t('assistant.assistant_navigation.close')}
        >
          <Icon type="close" role="presentation">
            close
          </Icon>
        </AssistantLink>
      </nav>
      {integrations && integrations.length > 0 ? (
        <nav
          className="assistant__nav-icons assistant__nav-integrations"
          ref={servicesRef}
          aria-label={t('assistant.integration_navigation.main')}
        >
          {integrations.slice(0, visibleServices).map((item) => (
            <AssistantIntegrationNavItem integration={item} key={item.id} />
          ))}
          {showMore ? (
            <AssistantLink
              variant="nav"
              to="/assistant/commandcenter"
              onClick={handleExploreClick}
              className="assistant__nav-link"
              aria-label={t('assistant.integration_navigation.more')}
            >
              <Icon type="more_horiz" role="presentation" />
            </AssistantLink>
          ) : null}
        </nav>
      ) : null}
    </>
  );
}

const mapStateToProps = (state: RootPatronState) => ({
  integrations: assistantSelectors.getIntegrations(state),
});

export default connect<
  AssistantNavProps,
  never,
  Record<string, never>,
  RootPatronState
>(mapStateToProps)(AssistantNav);
