import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { FlashMessage } from '../components/comments/comment-flash-message';
import { Comment, CommentPostResponse } from '../models/comments/types';
import CommentService from '../services/comment';
import { AxiosResponse } from 'axios';

type UseCommentFetcherProps = {
  contentId: number;
  commentId: number;
};

const useCommentFetcher = ({
  contentId,
  commentId,
}: UseCommentFetcherProps) => {
  const [comment, setComment] = useState<Comment>();
  const [isFetching, setIsFetching] = useState(true);
  const [message, setMessage] = useState<FlashMessage>();

  const { t } = useTranslation();

  const fetchComment = async () => {
    setIsFetching(true);

    try {
      const service = new CommentService({
        contentId,
        commentId,
      });
      const res = await service.fetch();

      handleFetchSuccess(res);
    } catch (err) {
      if (_.get(err, 'response')) {
        handleFetchFailure();
      } else {
        throw err;
      }
    }
  };

  const handleFetchSuccess = (res: AxiosResponse<CommentPostResponse>) => {
    const comment = {
      ...res.data.data.attributes,
      id: Number(res.data.data.id),
    };

    setComment(comment);
    setIsFetching(false);
  };

  const handleFetchFailure = () => {
    setMessage({ text: t('comments.errors.fetch'), type: 'error' });
    setIsFetching(false);
  };

  useEffect(() => {
    void fetchComment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    comment,
    message,
    isFetching,
  };
};

export default useCommentFetcher;
