import { useDispatch } from 'react-redux';
import useFeatureFlag from '../../../common/use-feature-flag';
import { Feature } from '../../../models/features/features';
import { createToastMessage, useToastMessages } from './toaster';
import uiActions from '../../../models/ui/actions';
import { useCallback, useEffect } from 'react';
import { getFeatureFlag } from '../../../models/features/features';
import { RootPatronState } from '../../../common/use-patron-selector';
import { usePatronSelector } from '../../../common/use-patron-selector';

//subset of ToastMessageType that is also compatible with flashMessage
export type LegacyMessageType = {
  type: 'success' | 'warning' | 'error';
  text: string;
  timeout?: number | false;
};

//to be used as long as we have the newUIEnabled feature flag. Handles selecting the right interface for the deprecated flashMessages and the new toaster.
//should be able to convert all usages of usePushToastOrFlashMessage to useToastMessages in the future with minimal hassle.
export const usePushToastOrFlashMessage = () => {
  const newUIEnabled = useFeatureFlag(Feature.NEW_UI_ENABLED);
  const { setToastMessage } = useToastMessages();
  const dispatch = useDispatch();

  const pushMessage = useCallback(
    (message: LegacyMessageType) => {
      if (newUIEnabled) {
        setToastMessage(createToastMessage(message));
      } else {
        dispatch(uiActions.addFlashMessage(message));
      }
    },
    [newUIEnabled, setToastMessage, dispatch]
  );

  return {
    pushMessage,
  };
};

//the next two items are specifically for cases when a redux action wants to set a toast message. redux does not have access to hooks, so we need to use this shim to set the toast message.
//components should not try to use this directly, but instead use usePushToastOrFlashMessage, as it handles multiple messages properly and is a lot less hacky.
export const dispatchToastOrFlashMessage = (
  message: LegacyMessageType,
  dispatch: any,
  getState: () => RootPatronState
) => {
  const newUIEnabled = getFeatureFlag(getState(), Feature.NEW_UI_ENABLED);

  if (newUIEnabled) {
    dispatch(uiActions.setToastMessage(message));
  } else {
    dispatch(uiActions.addFlashMessage(message));
  }
};

export const ToasterReduxShim: React.FC = () => {
  const { setToastMessage } = useToastMessages();
  const toastMessage = usePatronSelector((state) => state.ui.toastMessageShim);

  //whenever the shim state is updated, push that new message to the toaster
  useEffect(() => {
    if (toastMessage) {
      setToastMessage(createToastMessage(toastMessage));
    }
  }, [toastMessage, setToastMessage]);

  return null;
};
