import { BoxContentPreview } from '@socialchorus/box-components';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { RootPatronState } from '../../../common/use-patron-selector';
import { Feature, getFeatureFlag } from '../../../models/features/features';
import { uiOperations } from '../../../models/ui';
import styles from './styles.module.css';
import { useEffect } from 'react';

type RouteParams = {
  fileId: string;
};

export function BoxPreview() {
  // TODO FE-10634: Remove feature-flagged token once we are able to generate one ourselves
  const token = useSelector((state: RootPatronState) =>
    String(getFeatureFlag(state, Feature.BOX_INTEGRATION_TEMP_TOKEN))
  );
  const { fileId } = useParams<RouteParams>();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(uiOperations.hideHeader());

    return () => {
      dispatch(uiOperations.showHeader());
    };
  }, [dispatch]);

  return (
    <div className={styles.BoxPreviewContainer}>
      {token && (
        <BoxContentPreview showDownload={false} token={token} fileId={fileId} />
      )}
    </div>
  );
}
