import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar } from '../ui';

import { Button } from '../ui';
import CommentListItem from './comment-list-item';
import cx from 'classnames';

const CommentReplies = ({
  comment,
  context,
  onEdit,
  onDelete,
  onReport,
  onReplies,
}) => {
  const [isVisible, setIsVisible] = useState();

  const { t } = useTranslation();

  const handleRepliesToggle = () => {
    setIsVisible(!isVisible);

    onReplies(comment);
  };

  const replies = comment.replies || [];

  // enforcing only 40 comments shown until pagination supported
  const reply_count = comment.reply_count > 40 ? 40 : comment.reply_count;

  const replyButtonText = isVisible
    ? t('comments.hide_reply', { count: reply_count })
    : t('comments.show_reply', { count: reply_count });

  const previewClass = cx('comment__replies-preview', {
    'comment__replies-preview-closed': !isVisible,
  });

  useEffect(() => {
    setIsVisible(!!replies.length);
  }, [replies.length]);

  const reduceReplyAuthors = (authors) => {
    if (authors === undefined || authors.length === 0) {
      return [];
    }
    let ids = new Set();

    return authors.filter(
      (author) => !ids.has(author.id) && ids.add(author.id)
    );
  };

  const filteredReplyAuthors = useMemo(
    () => reduceReplyAuthors(comment.reply_authors),
    [comment.reply_authors]
  );

  return (
    <div className="comment__replies">
      <div className={'comment__replies-body'}>
        {isVisible
          ? replies.map((reply) => (
              <CommentListItem
                comment={reply}
                context={context}
                onEdit={onEdit}
                onDelete={onDelete}
                onReport={onReport}
                key={reply.id}
              />
            ))
          : null}

        {
          // TODO: add the following for pagination support:
          // <ViewTrigger onInview={() => onReplies(comment)} />
        }
      </div>
      <div className={previewClass}>
        {!isVisible ? (
          <div className={'comment__replies-preview-avatar-group'}>
            {filteredReplyAuthors.slice(0, 6).map((reply_author) => (
              <Avatar
                className="comment__replies-preview-avatar-group__avatar"
                src={reply_author.avatar.url}
                bgColor={reply_author.avatar.color}
                key={reply_author.id}
              />
            ))}
          </div>
        ) : null}
        <Button
          className="comment__replies-toggle pt-secondary-color"
          onClick={handleRepliesToggle}
          aria-label={replyButtonText}
        >
          {replyButtonText}
        </Button>
      </div>
    </div>
  );
};

export default CommentReplies;
