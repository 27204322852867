import { Button, Icon } from '@socialchorus/shared-ui-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { trackCommentEvent } from '../../../../../models/comments/analytics';
import { Comment, CommentReply } from '../../../../../models/comments/types';
import CommentService from '../../../../../services/comment';

type CommentActionLikeProps = {
  comment: Comment | CommentReply;
};

export function CommentActionLike({ comment }: CommentActionLikeProps) {
  const { t } = useTranslation();

  const [liked, setLiked] = useState(comment.liked);
  const [canLike, setCanLike] = useState(comment.can_like);
  const [likeCount, setLikeCount] = useState(comment.like_count);

  const commentService = new CommentService({
    contentId: Number(comment.content_id),
    commentId: comment.id,
  });

  const likeComment = () => {
    if (!canLike) return;

    trackCommentEvent('like', comment, !!comment.reply_to_post_number);

    commentService.like();

    setLiked(true);
    setCanLike(false);
    setLikeCount(likeCount + 1);
  };

  const unlikeComment = () => {
    trackCommentEvent('unlike', comment, !!comment.reply_to_post_number);

    commentService.unlike();

    setLiked(false);
    setCanLike(true);
    setLikeCount(Math.max(0, likeCount - 1));
  };

  const emptyLikes = likeCount == 0;

  return (
    <Button
      size="compact"
      variant="text"
      selected={liked}
      label={`${emptyLikes ? '' : likeCount}`}
      leftIcon={<Icon size={20}>thumb_up</Icon>}
      aria-label={
        canLike
          ? t(liked ? 'content.like_remove' : 'content.like_one')
          : undefined
      }
      onClick={liked ? unlikeComment : likeComment}
    />
  );
}
