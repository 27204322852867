import api from '../config/api';
import {
  CommentActionSuccess,
  CommentPostBatchResponse,
  CommentPostResponse,
} from '../models/comments/types';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

type CommentServiceConstructor<
  CommentId extends number | undefined = undefined
> = {
  contentId: number;
  commentId?: CommentId;
  replyToId?: number;
  postNumber?: number;
};

class CommentService<CommentId extends number | undefined = undefined> {
  contentId: number;
  commentId?: CommentId;
  replyToId?: number;
  postNumber?: number;

  constructor({
    contentId,
    commentId,
    replyToId,
    postNumber,
  }: CommentServiceConstructor<CommentId>) {
    this.commentId = commentId;
    this.contentId = contentId;
    this.replyToId = replyToId;
    this.postNumber = postNumber;
  }

  get baseUrl() {
    return `/contents/${this.contentId}/comments`;
  }

  get url() {
    return this.replyToId
      ? this.commentId // Replies
        ? `${this.baseUrl}/${this.replyToId}/replies/${this.commentId}`
        : `${this.baseUrl}/${this.replyToId}/replies`
      : this.commentId // Root comments
      ? `${this.baseUrl}/${this.commentId}`
      : `${this.baseUrl}`;
  }

  fetch = (
    config?: AxiosRequestConfig
  ): Promise<
    AxiosResponse<
      CommentId extends number ? CommentPostResponse : CommentPostBatchResponse
    >
  > => {
    return api.get(this.url, config);
  };

  fetchByPostNumber = (
    config?: AxiosRequestConfig
  ): Promise<
    AxiosResponse<
      CommentId extends number ? CommentPostResponse : CommentPostBatchResponse
    >
  > => {
    return api.get(`${this.baseUrl}/by_number/${this.postNumber}`, config);
  };

  fetchByFilter = (
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<CommentPostBatchResponse>> => {
    return api.get(`${this.baseUrl}/filter`, config);
  };

  report = () => {
    return api.post<CommentActionSuccess>(
      `${this.baseUrl}/${this.commentId}/report`
    );
  };

  destroy = () => {
    return api.delete<Record<string, never>>(
      `${this.baseUrl}/${this.commentId}`
    );
  };

  like = () => {
    return api.post<CommentActionSuccess>(
      `${this.baseUrl}/${this.commentId}/like`
    );
  };

  unlike = () => {
    return api.delete<CommentActionSuccess>(
      `${this.baseUrl}/${this.commentId}/like`
    );
  };

  highlight = () => {
    return api.post<CommentActionSuccess>(
      `${this.baseUrl}/${this.commentId}/highlight`
    );
  };

  unhighlight = () => {
    return api.delete<CommentActionSuccess>(
      `${this.baseUrl}/${this.commentId}/highlight`
    );
  };

  save = (body: string) => {
    const params = {
      body,
      raw_body: body,
      content_id: this.contentId,
      comment_id: this.replyToId || this.commentId,
      post_number: this.postNumber,
    };

    const saveMethod = this.commentId ? api.put : api.post;

    return saveMethod<CommentPostResponse>(this.url, params);
  };
}

export default CommentService;
