import { BoxContentExplorer } from '@socialchorus/box-components';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MemoryRouter, useLocation, useParams } from 'react-router-dom';
import { RootPatronState } from '../../../common/use-patron-selector';
import { Feature, getFeatureFlag } from '../../../models/features/features';
import { uiOperations } from '../../../models/ui';
import styles from './styles.module.css';

type RouteParams = {
  folderId: string;
};

export function ContentExplorer() {
  // TODO FE-10634: Remove feature-flagged token once we are able to generate one ourselves
  const token = useSelector((state: RootPatronState) =>
    String(getFeatureFlag(state, Feature.BOX_INTEGRATION_TEMP_TOKEN))
  );
  const { folderId } = useParams<RouteParams>();
  const location = useLocation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(uiOperations.hideHeader());

    return () => {
      dispatch(uiOperations.showHeader());
    };
  }, [dispatch]);

  return (
    <div className={styles.ContentExplorerContainer}>
      <MemoryRouter initialEntries={[location]} initialIndex={0}>
        <BoxContentExplorer
          folderId={folderId}
          token={token}
          canCreateNewFolder={false}
          canDelete={false}
          canDownload={false}
          canRename={false}
          canUpload={false}
          canShare={false}
          canSetShareAccess={false}
        />
      </MemoryRouter>
    </div>
  );
}
