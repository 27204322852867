import { BigIconButton } from '@socialchorus/shared-ui-components';
import { usePatronSelector } from '../../../../common/use-patron-selector';
import {
  AssistantIntegration,
  getIntegration,
} from '../../../../models/assistant/selectors';
import { IconButtonProps } from '../../../icon-button/icon-button';
import Tooltip from '../../../tooltip/tooltip';
import { LinkNavigation } from '@socialchorus/shared-ui-components/dist/components/Link';

export interface ShortcutButtonProps {
  id: AssistantIntegration['id'];
  label: string;
  iconSrc?: string;
  href?: string | LinkNavigation;
  onClick?: IconButtonProps['onClick'];
  className?: string;
}

const ShortcutButton = ({
  id,
  iconSrc,
  label,
  href,
  onClick,
  className,
}: ShortcutButtonProps) => {
  const integration = usePatronSelector((state) =>
    getIntegration(state, { integrationId: id })
  );

  const tooltipId = `shortcut-button-${id}--tooltip`;

  return (
    <Tooltip
      id={tooltipId}
      text={integration?.subject.description ?? ''}
      options={{ placement: 'bottom' }}
    >
      {({
        tooltipRef,
        onMouseEnter,
        onMouseLeave,
        onFocus,
        onBlur,
        onKeyDown,
      }) => {
        return (
          <BigIconButton
            role="link"
            imgSrc={iconSrc}
            label={label}
            aria-describedby={tooltipId}
            href={href}
            onClick={onClick}
            ref={tooltipRef}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            className={className}
          />
        );
      }}
    </Tooltip>
  );
};

export default ShortcutButton;
