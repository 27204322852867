import PropTypes from 'prop-types';

const Curtain = ({ onClick }) => {
  const handleKeyPress = (ev) => {
    if (ev.key === 'Space') {
      onClick();
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div className="curtain" onClick={onClick} onKeyDown={handleKeyPress} />
  );
};

Curtain.propTypes = {
  onClick: PropTypes.func,
};

export default Curtain;
