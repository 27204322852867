import { Author } from '../assistant/interfaces/Author';

export type CommentActionSuccess = {
  message: string;
};

export type CommentPostResponse = {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      body: string;
      raw_body: string;
      created_at: string;
      reply_count: number;
      post_number: number;
      reply_to_post_number: number | null;
      can_like: boolean;
      liked: boolean;
      like_count: number;
      can_edit: boolean;
      can_delete: boolean;
      content_id: string;
      can_report: boolean;
      can_defer_flags: boolean | null;
      reported: boolean | null;
      edited: boolean;
      topic_id: number;
      author: Author;
      reply_authors: Array<Author>;
      deleted: boolean;
      updated_at: string;
      can_highlight: boolean;
      highlighted: boolean;
      is_translatable: boolean;
      language: string;
      display_language: string;
    };
  };
};

export type CommentPostBatchResponse = {
  data: CommentPostResponse['data'][];
  meta: CommentPostResponseMeta;
};

export type CommentPostResponseMeta = {
  page_count: number;
  current_page: number;
  page_size: number;
  total_records: number;
};

export type Comment = CommentPostResponse['data']['attributes'] & {
  edited?: boolean;
  translationInProgress?: boolean;
};

export type CommentReply<T extends Comment | CommentWithReplies = Comment> =
  T & {
    reply_to_id: number;
  };

export type CommentWithReplies = Comment & {
  replies: CommentReply[];
  replies_page_count: number;
  replies_current_page: number;
};

export function isCommentWithReplies(
  comment: Comment | CommentWithReplies
): comment is CommentWithReplies {
  return (
    'replies' in comment &&
    'replies_page_count' in comment &&
    'replies_current_page' in comment
  );
}

export type CommentAssistantSuggestionType =
  | 'improve_writing'
  | 'fix_spelling_grammar'
  | 'use_concise_language'
  | 'change_tone';
export type CommentAssistantSuggestionSubtype =
  | 'professional'
  | 'casual'
  | 'straightforward'
  | 'confident'
  | 'friendly';

export type CommentAssistantSuggestionSelection =
  | {
      suggestionType: Exclude<CommentAssistantSuggestionType, 'change_tone'>;
    }
  | {
      suggestionType: Extract<CommentAssistantSuggestionType, 'change_tone'>;
      suggestionSubtype: CommentAssistantSuggestionSubtype;
    };
