import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { submissionChannelIds } from '../../models/content-submission/atoms';
import { Channel } from '../../models/channels/types';

import { Icon } from '../../components/ui';
import { KeyboardEventHandler } from 'react';

type ChannelsChannelProps = {
  idPrefix: string;
  channel: Channel;
  disabled?: boolean;
  onChange?: (id: string, val: boolean) => void;
};

const ChannelsChannel = ({
  idPrefix,
  channel,
  disabled,
  onChange,
}: ChannelsChannelProps) => {
  const { t } = useTranslation();
  const [selectedChannelIds, setSelectedChannelIds] =
    useRecoilState<string[]>(submissionChannelIds);

  const isSelected = selectedChannelIds.includes(channel.id);

  const elementId = `${idPrefix}--channel-${channel.id}`;

  const handleSelect = () => {
    const channelIds = !isSelected
      ? [...selectedChannelIds, channel.id]
      : selectedChannelIds.filter((cid) => cid !== channel.id);

    setSelectedChannelIds(channelIds);
    onChange?.(elementId, !isSelected);
  };

  const handleKeyDown: KeyboardEventHandler = (e) => {
    if (e.key === 'ArrowUp') {
      document.activeElement?.previousElementSibling instanceof HTMLElement &&
        document.activeElement.previousElementSibling.focus();
    }

    if (e.key === 'ArrowDown') {
      document.activeElement?.nextElementSibling instanceof HTMLElement &&
        document.activeElement.nextElementSibling.focus();
    }

    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();

      document.activeElement instanceof HTMLElement &&
        !disabled &&
        document.activeElement.click();
    }
  };

  const followerCountLabel = `${channel.follower_count} ${
    channel.follower_count > 1
      ? t('screens.explore.followers.other')
      : t('screens.explore.followers.one')
  }`;

  return (
    <div
      id={elementId}
      className="content-submission-channel"
      role="option"
      tabIndex={-1} // Listbox options are navigated using arrow key events: https://www.w3.org/WAI/ARIA/apg/patterns/listbox/
      onClick={handleSelect}
      onKeyDown={handleKeyDown}
      aria-selected={isSelected}
      aria-disabled={!!disabled}
      aria-labelledby={`${elementId}--title`}
      aria-describedby={[
        `${elementId}--description`,
        `${elementId}--followers`,
      ].join(' ')}
    >
      <div
        className="content-submission-channel__image"
        style={{
          backgroundImage: channel.background_image_url
            ? `url(${channel.background_image_url})`
            : undefined,
          backgroundColor: !channel.background_image_url
            ? channel.background_color
            : undefined,
        }}
      >
        <img
          src={channel.background_image_url ?? undefined}
          alt={channel.name}
          className="image"
        />
      </div>

      <div className="content-submission-channel__body">
        <div
          id={`${elementId}--title`}
          className="content-submission-channel__title"
        >
          {channel.name}
        </div>

        <div
          id={`${elementId}--description`}
          className="content-submission-channel__description"
        >
          {channel.description}
        </div>

        <div
          id={`${elementId}--followers`}
          aria-label={followerCountLabel}
          className="content-submission-channel__followers"
        >
          <Icon type="people" />
          {channel.follower_count}
        </div>
      </div>

      <div className="content-submission-channel__selector">
        <Selector selected={!!isSelected} />
      </div>
    </div>
  );
};

type SelectorProps = {
  selected?: boolean;
};

const Selector = ({ selected }: SelectorProps) => {
  const className = cx('selector', {
    'selector--selected': selected,
  });

  return (
    <span className={className}>
      <Icon type={selected ? 'check_circle' : 'radio_button_unchecked'} />
    </span>
  );
};

export default ChannelsChannel;
