import { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootPatronState } from '../../common/use-patron-selector';
import useFeatureFlag from '../../common/use-feature-flag';
import useMenuClick from '../../common/use-menu-click';
import { programSelectors } from '../../models/program';
import { Feature } from '../../models/features/features';
import { Button, NewContentIcon } from '../ui';
import SiteHeaderSubmitPopover from './site-header-submit-popover';
import SiteHeaderSubmitImage from './site-header-submit-image';
import SiteHeaderSubmitLinks from './site-header-submit-links';
import { trackSubmitClick } from '../../models/content-submission/analytics';
import './site-header-submit.scss';
import { ContentType } from '../../models/content/types';
import Tooltip from '../tooltip/tooltip';

type StateProps = ReturnType<typeof mapStateToProps>;
type OwnProps = {
  searchBarInHeaderEnabled?: boolean;
};

type SiteHeaderSubmitProps = StateProps & OwnProps;

const SiteHeaderSubmit = ({
  searchBarInHeaderEnabled,
}: SiteHeaderSubmitProps) => {
  const [isActive, setIsActive] = useState(false);
  const [contentType, setContentType] = useState<ContentType | null>(null);
  const { t } = useTranslation();

  const handleTriggerClick = () => {
    setIsActive((a) => !a);
    if (!isActive) {
      trackSubmitClick();
    }
  };

  const handleLinkOver = setContentType;

  const ref = useMenuClick({
    disabled: !isActive,
    onClick: handleTriggerClick,
  });

  const newUIEnabled = useFeatureFlag(Feature.NEW_UI_ENABLED);

  //only used when new ui is not enabled. instead, using the icon component in the button. aria-label is on the icon component.
  const submitImgSrc = searchBarInHeaderEnabled
    ? '/images/icons/pencil-bordered-icon.svg'
    : '/images/icons/new-post-icon.svg';

  const submitButtonLabel = t('content_submission.title');

  return (
    <>
      {/* Prefetch image assets */}
      <link rel="prefetch" href="/images/submit-image-video.svg" />
      <link rel="prefetch" href="/images/submit-image-image.svg" />
      <link rel="prefetch" href="/images/submit-image-note.svg" />
      <link rel="prefetch" href="/images/submit-image-article.svg" />
      <link rel="prefetch" href="/images/submit-image-link.svg" />
      <link rel="prefetch" href="/images/submit-image-default.svg" />

      <div className="site-header-submit" ref={ref}>
        <Tooltip
          aria-hidden
          text={submitButtonLabel}
          options={{ placement: 'bottom' }}
        >
          <Button
            className="submit-link"
            onClick={handleTriggerClick}
            aria-label={submitButtonLabel}
            iconComponent={newUIEnabled ? <NewContentIcon /> : undefined}
            aria-expanded={isActive}
          >
            {!newUIEnabled && (
              <img src={submitImgSrc} className="icon" alt="Submit content" />
            )}
          </Button>
        </Tooltip>

        {isActive ? (
          <SiteHeaderSubmitPopover>
            <SiteHeaderSubmitImage contentType={contentType} />

            <SiteHeaderSubmitLinks
              onLinkOver={handleLinkOver}
              onClick={() => setIsActive(false)}
            />
          </SiteHeaderSubmitPopover>
        ) : null}
      </div>
    </>
  );
};

const mapStateToProps = (state: RootPatronState) => ({
  searchBarInHeaderEnabled: programSelectors.getSearchBarInHeaderEnabled(state),
});

export default connect(mapStateToProps)(SiteHeaderSubmit);
