import {
  Avatar,
  FeedCard as SharedFeedCard,
  Icon,
  Tag,
} from '@socialchorus/shared-ui-components';
import { ComponentProps, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useFeatureFlag from '../../../../../common/use-feature-flag';
import useInview from '../../../../../common/use-inview';
import { usePatronSelector } from '../../../../../common/use-patron-selector';
import useScreenSize from '../../../../../common/use-screen-size';
import { timeAgoOrDate } from '../../../../../lib/date-formatter';
import { contentSelectors } from '../../../../../models/content';
import { trackContentCardView } from '../../../../../models/content/analytics';
import {
  getContentAuthorAvatarSrc,
  getContentAuthorProfileLink,
} from '../../../../../models/content/helpers';
import { Content } from '../../../../../models/content/types';
import { Feature } from '../../../../../models/features/features';
import { FeedCardActions } from './feed-card-actions';
import { useLinkNavigation } from '../../../../../common/use-link-navigation';
import { programSelectors } from '../../../../../models/program';
import ContentActionTranslateWrapper from '../../../content-actions/content-action-translate-wrapper';

const DEFAULT_AVATAR_SRC =
  'https://lib.socialchorus.com/mojo/public/default_avatar_new.png';

type FeedCardProps = {
  content: Content;
  analyticsData: IAnalyticsData;
  onClick?: () => void;
};

export function FeedCard({ content, analyticsData, onClick }: FeedCardProps) {
  const contentUrl = usePatronSelector((state) =>
    contentSelectors.getContentDetailUrl(state, { contentId: content.id })
  );
  const viewCount = usePatronSelector((s) =>
    contentSelectors.getContentViewCount(s, { contentId: content.id })
  );
  const viewsEnabled = usePatronSelector((s) =>
    programSelectors.getFeedCardViewsEnabled(s)
  );

  //gotcha: must use the title from the selectors and not the passed in content object. the selectors will be updated with the translated title, while the content object is from the feed query on load.
  //to refactor: extract the content prop from here and only pass in the id, then use the selectors to get the content attributes
  const title = usePatronSelector((state) =>
    contentSelectors.getContentTitle(state, { contentId: content.id })
  );
  const description = usePatronSelector((state) =>
    contentSelectors.getContentSummary(state, { contentId: content.id })
  );

  const getLinkNavigation = useLinkNavigation();
  const { isMobile } = useScreenSize();
  const { t } = useTranslation();

  const ref = useRef<HTMLDivElement>(null);

  useInview({
    ref,
    once: true,
    onInview: () => trackContentCardView(content.id, analyticsData),
  });

  const contentImageUrl =
    content.feed_preview_image_urls[0] || content.background_image_url;

  const cardImage: ComponentProps<typeof SharedFeedCard.Image>['image'] =
    content.content_type === 'note'
      ? { type: 'note-icon', color: content.border_color }
      : {
          url: contentImageUrl,
          alt: content.source_image_alt,
        };

  const viewWord = t('content.view', {
    count: viewCount,
  });

  const avatarSrc = getContentAuthorAvatarSrc(content) || DEFAULT_AVATAR_SRC;

  const authorId = Number(content.author_id);
  const contentAuthorLink =
    Number.isInteger(authorId) && authorId > 0
      ? getLinkNavigation(getContentAuthorProfileLink(authorId))
      : undefined;

  const additionalMetadata = [timeAgoOrDate(content.published_at)];
  if (viewsEnabled) {
    const viewDisplayText =
      t('compact_number', { value: viewCount }) + ` ${viewWord}`;

    additionalMetadata.push(viewDisplayText);
  }

  const pinned = content.pinned;
  const labelText = pinned
    ? t('content.pinned')
    : content.buttons?.[0]?.buttonText; //featured label text

  return (
    <SharedFeedCard.Root
      ref={ref}
      layout={isMobile ? 'vertical' : 'horizontal'}
      href={getLinkNavigation(contentUrl)}
      onClick={onClick}
    >
      {labelText ? (
        <SharedFeedCard.Label>
          {content.pinned ? <Icon size={18}>push_pin</Icon> : null}
          {labelText}
        </SharedFeedCard.Label>
      ) : undefined}
      <SharedFeedCard.Main>
        <SharedFeedCard.Content>
          <SharedFeedCard.Title //refactor opportunity: generateMentionHref prop could be in the root
            title={title}
            description={description}
            generateMentionHref={(userId) =>
              getLinkNavigation(`/profiles/${userId}`)
            }
          />
          <SharedFeedCard.Description
            title={title}
            description={description}
            generateMentionHref={(userId) =>
              getLinkNavigation(`/profiles/${userId}`)
            }
          />
          <ContentActionTranslateWrapper
            contentId={content.id}
            analyticsData={analyticsData}
          >
            {({ canTranslate, handleClick, isTranslated }) => {
              return canTranslate ? (
                <SharedFeedCard.TranslateButton
                  isTranslated={isTranslated}
                  onClick={handleClick}
                />
              ) : null;
            }}
          </ContentActionTranslateWrapper>
        </SharedFeedCard.Content>
        {
          <SharedFeedCard.Tags
            display={1}
            popoverTitle={t('content.posted_in_topics')}
            popoverWidth="200px"
            tags={content.content_channels.map((channel) => (
              <Tag
                key={channel.id}
                label={channel.name}
                href={getLinkNavigation(`/channels/${channel.id}`)}
              />
            ))}
          />
        }
      </SharedFeedCard.Main>
      <SharedFeedCard.Image image={cardImage} />
      <SharedFeedCard.Footer>
        <SharedFeedCard.Metadata
          additionalMetadata={additionalMetadata}
          authorAvatarComponent={
            <Avatar imgSrc={avatarSrc} shape="circle" size="small" />
          }
          authorName={content.source_name}
          authorHref={contentAuthorLink}
        />
        <FeedCardActions content={content} analyticsData={analyticsData} />
      </SharedFeedCard.Footer>
    </SharedFeedCard.Root>
  );
}
