import { useState } from 'react';
import { CommaArrayParam, useSearchQueryParams } from '../../query-params';

/**
 * useAppliedFilters is a hook that returns the filters that are currently applied to the search feature.
 * It reads the query params and returns the values of the given parameter.
 */
export function useAppliedFilters(parameter: string | string[]) {
  const [query] = useSearchQueryParams();
  const parameterValues = query[parameter as keyof typeof query];
  const decodedParameterValues = parameterValues
    ? CommaArrayParam.decode(String(parameterValues))
    : null;

  const appliedFilters = [];

  if (decodedParameterValues && decodedParameterValues.length > 0) {
    for (const value of decodedParameterValues) {
      if (!value) {
        continue;
      }
      appliedFilters.push(value);
    }
  }

  return appliedFilters;
}

export function useSelectedCheckedOptions(filterParam: string) {
  const initialCheckedOptions = useAppliedFilters(filterParam);
  const [checkedOptions, setCheckedOptions] = useState<string[]>(
    initialCheckedOptions
  );

  return [checkedOptions, setCheckedOptions] as const;
}
