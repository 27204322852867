import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import { redirectToLogin } from './lib/auth';
import { trackEmailLinkClick } from './lib/email-link-tracker';
import { appConfigSelectors, appConfigOperations } from './models/app-config';
import { programOperations } from './models/program';
import { uiOperations } from './models/ui';
import {
  programMembershipSelectors,
  programMembershipOperations,
} from './models/program-membership';
import { advocateSelectors, advocateOperations } from './models/advocate';
import { channelSelectors, channelOperations } from './models/channels';
import { assistantOperations } from './models/assistant';

import ProgramMembershipService from './services/program-membership';
import AdvocateService from './services/advocate';
import ChannelService from './services/channel';
import AssistantService from './services/assistant';

import { ID as LoadingErrorModalID } from './components/loading-error-modal/loading-error-modal';
import AuthService from './services/auth';
import { getCookieValue, setCookieValue } from './lib/cookies';
import { FeatureFlagsService } from './services/feature-flags';
import {
  Feature,
  getFeatureFlag,
  setFeatureFlags,
} from './models/features/features';

class PatronData extends React.Component {
  state = { isFetching: true, isError: false };

  componentDidMount() {
    this.props.setAppConfig(this.props.config);
    this.props.setProgram(this.props.program);

    if (this.props.program)
      document.title = `${this.props.t('nav_links.home')} | ${
        this.props.program.name
      }`;

    Promise.all([
      this.fetchProgramMembership(),
      this.fetchAdvocate(),
      this.fetchChannels(),
      this.fetchFeatureFlags(),
    ])
      .then(this.handleFetchSuccess)
      .catch(this.handleFetchFailure);
  }

  fetchProgramMembership = () => {
    return new ProgramMembershipService()
      .fetch()
      .then((res) => res.data.program_memberships[0])
      .then(this.props.setProgramMembership);
  };

  fetchFeatureFlags = () => {
    return new FeatureFlagsService(this.props.program.id)
      .fetch()
      .then(this.props.setFeatureFlags);
  };

  fetchAdvocate = () => {
    return new AdvocateService(this.props.advocateId)
      .fetch()
      .then((res) => res.data.data.profile)
      .then(this.props.setAdvocate);
  };

  fetchChannels = () => {
    return new ChannelService()
      .fetchAll()
      .then((res) => res.data.content_channels)
      .then(this.props.setChannels);
  };

  fetchAssistant = () => {
    const service = new AssistantService(this.props.program.id);
    Promise.all([service.fetchInbox(), service.fetchInboxCount()]).then(
      ([inbox, count]) => {
        this.props.setInboxCount(count.data.count);
        this.props.setInbox(inbox);
      }
    );
  };

  fetchEntitlements = () => {
    new AuthService()
      .fetchEntitlements(this.props.advocateEmail)
      .then((res) => res.data.programs)
      .then(this.props.setAdvocateEntitlements);
  };

  render() {
    return this.props.children(this.state);
  }

  handleFetchSuccess = () => {
    this.initStyle();
    this.initAnalyticsIds();
    this.initAnalytics();
    this.initPendo();
    this.fetchAssistant();
    this.fetchEntitlements();

    this.setState({ isFetching: false });
  };

  handleFetchFailure = (err) => {
    if (!err.response) {
      this.setState({ isError: true });
      throw err;
    }

    err.response.status === 401 || err.response.status === 403
      ? redirectToLogin()
      : this.props.displayOverlay(LoadingErrorModalID);
  };

  initAnalyticsIds = () => {
    const ANALYTICS_USER_ID_KEY = 'ajs_user_id';
    const analyticsUserId = `"${this.props.programMembership.id}"`;

    if (localStorage.getItem(ANALYTICS_USER_ID_KEY) !== analyticsUserId) {
      localStorage.setItem(ANALYTICS_USER_ID_KEY, analyticsUserId);
    }

    if (getCookieValue(ANALYTICS_USER_ID_KEY) !== analyticsUserId) {
      setCookieValue(ANALYTICS_USER_ID_KEY, analyticsUserId, true, {
        domain: `.${window.location.hostname}`,
        sameSite: 'lax',
      });
    }
  };

  initAnalytics = () => {
    const {
      config: { sega_service },
    } = this.props;

    const title = document.head.querySelector('title');
    const sega = document.createElement('script');
    sega.async = true;
    sega.src = sega_service;
    title.parentNode.insertBefore(sega, title.nextSibling);

    sega.onload = () => {
      this.identify();
    };
  };

  identify = () => {
    const identifier =
      this.props.programMembership && this.props.programMembership.id;

    window.analytics &&
      window.analytics.ready(() => {
        window.analytics.identify(identifier, {
          program_id: this.props.program.id,
          program_title: this.props.program.name,
          brand_id: this.props.program.brand_id,
          brand_name: this.props.program.brand_name,
          advocate_id: this.props.advocate.id,
        });
        trackEmailLinkClick(window.analytics, {
          user_id: identifier,
          program_id: this.props.program.id,
          brand_id: this.props.program.brand_id,
        });
      });
  };

  initPendo = () => {
    if (this.props.programMembership.status !== 'active') return;

    window.pendo &&
      window.pendo.initialize({
        visitor: {
          id: this.props.programMembership.id,
          email: this.props.advocate.email,
          status: this.props.programMembership.status,
        },
        account: {
          id: this.props.program.id,
          name: this.props.program.name,
        },
      });
  };

  initStyle = () => {
    if (this.props.newUIEnabled) {
      document.documentElement.style.setProperty('--content-width', '1184px');
      document.documentElement.style.setProperty('--content-padding', '20px');
    }
  };
}

const mapStateToProps = (state, ownProps) => ({
  segmentKey: appConfigSelectors.getSegmentKey(state, ownProps),
  programMembership: programMembershipSelectors.getProgramMembership(
    state,
    ownProps
  ),
  advocate: advocateSelectors.getAdvocate(state, ownProps),
  advocateEmail: advocateSelectors.getAdvocateEmail(state, ownProps),
  channels: channelSelectors.getChannels(state, ownProps),
  newUIEnabled: getFeatureFlag(state, Feature.NEW_UI_ENABLED),
});

const mapDispatchToProps = {
  setAppConfig: appConfigOperations.setAppConfig,
  setProgram: programOperations.setProgram,
  setProgramMembership: programMembershipOperations.setProgramMembership,
  setAdvocate: advocateOperations.setAdvocate,
  setAdvocateEntitlements: advocateOperations.setAdvocateEntitlements,
  setChannels: channelOperations.setChannels,
  setFeatureFlags: setFeatureFlags,
  displayOverlay: uiOperations.displayOverlay,
  setInboxCount: assistantOperations.setInboxCount,
  setInbox: assistantOperations.setInbox,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(PatronData);
