import cx from 'classnames';

import Channel from './channels-channel';
import { useSelector } from 'react-redux';
import { Feature, getFeatureFlag } from '../../models/features/features';
import { Channel as ChannelModel } from '../../models/channels/types';
import { RootPatronState } from '../../common/use-patron-selector';

type ChannelListProps = {
  idPrefix: string;
  channels: ChannelModel[];
  disabled?: boolean;
  onChange?: (id: string, val: boolean) => void;
};

const ChannelList = ({
  idPrefix,
  channels,
  disabled,
  onChange,
}: ChannelListProps) => {
  const className = cx('content-submission-channel-list', {
    'content-submission-channel-list--disabled': disabled,
  });

  const newTopicsString = useSelector((state: RootPatronState) =>
    getFeatureFlag(state, Feature.CHANNELS_TO_TOPICS_ENABLED)
  );
  const channelsString = newTopicsString ? 'topics' : 'channels';

  return (
    <div className={className}>
      {channels.length
        ? channels.map((channel) => (
            <Channel
              idPrefix={idPrefix}
              channel={channel}
              disabled={disabled}
              key={channel.id}
              onChange={onChange}
            />
          ))
        : `No matching ${channelsString} found.`}
    </div>
  );
};

export default ChannelList;
