import { createSelector } from 'reselect';

const getOverlays = (state) => state.ui.overlays || [];

const getOverlayIsActive = createSelector(
  [getOverlays],
  (overlays) => overlays.length > 0
);

export default {
  getOverlayIsActive,
};
