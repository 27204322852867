import { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useSetRecoilState, useRecoilState } from 'recoil';
import { contentOperations } from '../models/content/index.js';
import {
  contentFeedFamily,
  setContentFeedItems,
} from '../models/feed/recoil-state';
import FeedService, { FeedServiceResponse } from '../services/feed';
import { Content } from '../models/content/types';
import { AxiosResponse } from 'axios';
import { ContentFeedFamilyState } from '../models/feed/types';
import { Feature } from '../models/features/features';
import useFeatureFlag from './use-feature-flag';

interface ContentFeedOptions {
  query?: string;
  filter?: string;
  channelId?: string;
  advocateId?: string;
  advocateFilter?: string;
  perPage?: number;
  manualFetch?: boolean;
  includeTypes?: string[];
  initiativeId?: string;
  pinned?: boolean;
}

function useContentFeed({
  query,
  filter,
  channelId,
  advocateId,
  advocateFilter,
  perPage,
  manualFetch,
  includeTypes,
  initiativeId,
  pinned,
}: ContentFeedOptions = {}) {
  const dispatch = useDispatch();

  //will cache the feed state in global state with this key. If we ever render 2 feeds that have the same key, they will be displayed with the same contents.
  const feedCacheKey = [
    query,
    filter,
    channelId,
    advocateId,
    advocateFilter,
    perPage,
    manualFetch,
    includeTypes,
    initiativeId,
  ].join('|');

  const [
    { feedItems, page, numPages, pageSize, total, canLoadMore, loaded },
    setFeed,
  ] = useRecoilState<ContentFeedFamilyState>(contentFeedFamily(feedCacheKey));

  const setFeedItems = useSetRecoilState(setContentFeedItems(feedCacheKey));

  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState<unknown>();

  const addContentsToStore = useCallback(
    (contents: Content[]) => dispatch(contentOperations.addContents(contents)),
    [dispatch]
  );

  const tagUserEnabled = useFeatureFlag(Feature.TAG_USER_ENABLED);
  const advancedSearchEnabled = useFeatureFlag(Feature.IMPROVED_SEARCH);

  const feedService = useMemo(
    () =>
      new FeedService({
        query,
        filter,
        channelId,
        advocateId,
        advocateFilter,
        includeTypes,
        initiativeId,
        mention_format: tagUserEnabled ? 'with_metadata' : undefined,
      }),
    [
      advocateFilter,
      advocateId,
      channelId,
      filter,
      includeTypes,
      initiativeId,
      query,
      tagUserEnabled,
    ]
  );

  const handleFetchFeedSuccess = useCallback(
    (res: AxiosResponse<FeedServiceResponse>) => {
      const items = (res.data.feed || res.data.contents)
        .map((fi) => fi.attributes)
        .filter((fi) =>
          Object.prototype.hasOwnProperty.call(fi, 'content_type')
        );
      const [curPage, pageSize] =
        res.data.meta && res.data.meta.page
          ? [
              res.data.meta.page.page || res.data.meta.page.number,
              res.data.meta.page.size,
            ]
          : [1, 0];

      if (perPage && items.length > perPage) {
        items.splice(perPage);
      }

      addContentsToStore(items);

      setFeed(({ feedItems }) => ({
        feedItems: curPage === 1 ? items : [...feedItems, ...items],
        page: curPage,
        canLoadMore: items.length >= pageSize,
        total:
          (advancedSearchEnabled
            ? res.data.meta.total_results
            : res.data.meta.total) || 0,
        numPages: advancedSearchEnabled
          ? res.data.meta.total_pages
          : Math.ceil(
              (res.data.meta.total || 0) / (res.data.meta.page.size || 20)
            ),
        pageSize: advancedSearchEnabled
          ? res.data.meta.per_page
          : res.data.meta.page.size,
        loaded: true,
      }));
      setFetching(false);
    },
    [addContentsToStore, perPage, setFeed]
  );

  const handleFetchFeedError = (err: unknown) => {
    console.error(err);

    setError(err);
  };

  const fetchFeed = useCallback(
    (resetPage = false) => {
      setFetching(true);

      if (resetPage) setFeedItems([]);

      const curPage = resetPage || !page ? undefined : page + 1;

      feedService
        .fetch({ page: curPage, perPage, pinned })
        .then(handleFetchFeedSuccess)
        .catch(handleFetchFeedError);
    },
    [feedService, handleFetchFeedSuccess, page, perPage, pinned, setFeedItems]
  );

  useEffect(() => {
    if (!manualFetch && !loaded) {
      fetchFeed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedCacheKey, manualFetch]);

  //DO NOT USE THE CONTENT IN `feedItems` DIRECTLY, USE THE CONTENT IN THE STORE BY REFERNCING THE ID OF THE CONTENT IN `feedItems`. (This is because the content in here is not updated when the content in the store is updated.)
  //This mostly just applies to the title for translations, but its a secret time bomb just waiting to blow. Theres a lot of places in the repo where this rule is broken, but please do not add any more debt onto the pile.
  return {
    fetching: !loaded || fetching,
    loaded,
    feedItems,
    page,
    numPages,
    pageSize,
    canLoadMore,
    error,
    total,
    fetchFeed,
  };
}

export default useContentFeed;
