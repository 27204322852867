import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useTranslation } from 'react-i18next';

import { trackCommentEvent } from '../../models/comments/analytics';
import { Comment, CommentWithReplies } from '../../models/comments/types';
import { Button, Icon } from '../ui';

type CommentActionMoreProps = {
  comment: Comment | CommentWithReplies;
  onEdit?: (comment: Comment) => void;
  onDelete?: (comment: Comment) => void;
  onReport?: (comment: Comment) => void;
  onHighlight?: (comment: Comment) => void;
};

const CommentActionMore = ({
  comment,
  onEdit,
  onDelete,
  onReport,
  onHighlight,
}: CommentActionMoreProps) => {
  const { t } = useTranslation();

  const handleEditClick = () => {
    trackCommentEvent('edit', comment, !!comment.reply_to_post_number);
    onEdit?.(comment);
  };

  const handleDeleteClick = () => {
    trackCommentEvent('delete', comment, !!comment.reply_to_post_number);
    onDelete?.(comment);
  };

  const handleReportClick = () => {
    trackCommentEvent('report', comment, !!comment.reply_to_post_number);
    onReport?.(comment);
  };

  const handleHighlightClick = () => {
    const eventName = comment.highlighted ? 'unhighlight' : 'highlight';
    trackCommentEvent(eventName, comment, !!comment.reply_to_post_number);
    onHighlight?.(comment);
  };

  const commentReportText = comment.reported
    ? t('comments.state.reported')
    : t('comments.actions.report');

  const commentHighlightText = comment.highlighted
    ? t('common.unhighlight')
    : t('common.highlight');

  return (
    <div className="comment-action-more comment__action comment__action--more">
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <Button
            className="action-arrow"
            title={t('comments.actions.label')}
            aria-label={t('comments.actions.label')}
          >
            <Icon type="more_horiz" />
          </Button>
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <DropdownMenu.Content className="dropdown__popover" align="start">
            {onHighlight && comment.can_highlight && (
              <DropdownMenu.Item className="comment-action-more__item">
                <Button
                  onClick={handleHighlightClick}
                  aria-label={t('common.highlight')}
                >
                  <Icon type="star" className="mr-10" />
                  {commentHighlightText}
                </Button>
              </DropdownMenu.Item>
            )}

            {(comment.can_report || comment.reported) && (
              <DropdownMenu.Item className="comment-action-more__item">
                <Button
                  disabled={comment.reported ?? undefined}
                  onClick={handleReportClick}
                  aria-label={commentReportText}
                >
                  <Icon type="flag" className="mr-10" />
                  {commentReportText}
                </Button>
              </DropdownMenu.Item>
            )}

            {comment.can_edit && (
              <DropdownMenu.Item className="comment-action-more__item">
                <Button onClick={handleEditClick} aria-label={t('common.edit')}>
                  <Icon type="edit" className="mr-10" />
                  {t('common.edit')}
                </Button>
              </DropdownMenu.Item>
            )}

            {comment.can_delete && (
              <DropdownMenu.Item className="comment-action-more__item">
                <Button
                  onClick={handleDeleteClick}
                  aria-label={t('common.delete')}
                >
                  <Icon type="delete" className="mr-10" />
                  {t('common.delete')}
                </Button>
              </DropdownMenu.Item>
            )}
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </div>
  );
};

export default CommentActionMore;
